/* General widget styles */
.widget {
	box-shadow: 0 6px 8px -4px rgba(50, 50, 50, 0.16);

	&.hidden {
		display: none;
	}

	.sub-nav-tabs {
		* {
			padding: 0 0 4px 0;
		}

		width: 100%;
		font-size: 0.9em;
		margin-bottom: 6px;
	}

	.data-grid-container {
		margin: 0;

		.data-grid-header {
			display: none;
		}

		.data-grid {
			margin: 0px;
		}

		.data-grid-footer {
			display: none;
		}
	}

	ion-button.btn-alt {
		--background: #{$theme-beta-secondary} !important;
	}
}

.widget-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
}

.widget-header {
	color: #fff;
	padding: $padding-small;
	cursor: grab;
	font-weight: normal;
	box-shadow: 0 3px 0px 0px #{$theme-beta-light};
	z-index: 1;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.widget-content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: $padding-small;
	background-color: white;
}

.widget-footer {
	display: flex;
	align-items: center;
	justify-content: end;
	padding: $padding-xsmall;
	min-height: 50px;
	overflow: hidden;
	font-size: 0.9em;
	background-color: var(--ion-color-light);
	gap: 10px;

	& > div:first-child {
		justify-content: start;
	}

	& > div {
		display: flex;
		align-items: center;
		justify-content: end;
		flex-wrap: wrap;
		width: 100%;
		gap: 9px;
	}

	p {
		margin: 0;
		font-weight: 600;
	}

	ion-label {
		white-space: nowrap;
	}

	ion-button {
		margin: 0;
	}
}

.widget-list {
	padding: 0;
	--ion-item-background: transparent;

	ion-item {
		--min-height: 32px;

		ion-label {
			margin: 0;
		}
	}
}

/* Offset the resize handle to prevent it from interferring with any footer items */
.react-resizable-handle {
	width: 18px !important;
	height: 18px !important;
	right: -4px !important;
	bottom: -4px !important;
	background-position: 5px 5px !important;
}
.react-grid-item > .react-resizable-handle::after {
	right: 7px;
	bottom: 7px;
}

/* Ensure controls can be covered by other widgets */
ion-button,
.react-select-container {
	z-index: 0 !important;
}

/* Widget plaques */
.widget-plaques-container {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	gap: 16px;
	padding: 0 26px;
}

.widget-plaque {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-width: 88px;
	padding: 10px;
	border-left-width: 4px;
	border-left-style: solid;
	background-color: $theme-beta-light;

	svg {
		font-size: 5em;
		border: 4px solid white;
		border-radius: 50%;
		padding: 18px;
	}

	.title {
		font-size: 1em;
		letter-spacing: -0.02em;
	}

	.stat {
		font-size: 2em;
	}
}

.btn-widget-plaque {
	height: auto !important;
	margin: auto 10%;
	--border-radius: 0;
	--padding-start: 0;
	--padding-end: 0;
	--background: #{$theme-beta-light} !important;

	.widget-plaque {
		background-color: transparent !important;
	}
}

/* Widget security categories */
.widget-container {
	&[class*='company'] {
		.widget-header {
			border-left: 4px solid $theme-beta-primary-dark;
			background-color: $theme-beta-primary;
		}

		ion-button {
			--background: #{$theme-beta-primary};
			--box-shadow: none;
		}
	}

	&[class*='personal'] {
		.widget-header {
			border-left: 4px solid $theme-beta-secondary-dark;
			background-color: $theme-beta-secondary;
		}

		.btn-widget-plaque {
			--background-hover: #{$theme-beta-secondary};
			--ripple-color: #{$theme-beta-secondary};
		}

		.widget-plaque {
			border-left-color: $theme-beta-secondary;

			svg {
				color: $theme-beta-secondary;
			}
		}

		ion-button {
			--background: #{$theme-beta-primary};
			--box-shadow: none;
		}
	}

	&[class*='features'] {
		.widget-header {
			border-left: 4px solid $theme-beta-tertiary-dark;
			background-color: $theme-beta-tertiary;
		}

		.btn-widget-plaque {
			--background-hover: #{$theme-beta-tertiary};
			--ripple-color: #{$theme-beta-tertiary};
		}

		.widget-plaque {
			border-left-color: $theme-beta-tertiary;

			svg {
				color: $theme-beta-tertiary;
			}
		}

		ion-button {
			--background: #{$theme-beta-tertiary};
			--box-shadow: none;
		}
	}
}

/* Widget specifics */
.dashboard-personal-requests:not(.view-history),
.dashboard-personal-sick:not(.view-history) {
	&.dashboard-personal-requests {
		.widget-plaques-container {
			padding: 18px 26px 26px 26px !important;
		}
	}

	&.dashboard-personal-sick {
		.widget-content {
			justify-content: center;
		}
	}

	.widget-plaque {
		/* Keep all items the same width */
		flex: 1 1 0px !important;
		width: 0;
		flex-direction: column;
	}

	.progress-bar-container {
		display: flex;
		justify-content: space-around;
		padding: 0px 26px;

		ion-progress-bar {
			height: 8px;
			min-width: 400px;
			--background: #{$theme-beta-light};
			--progress-background: #{$theme-beta-tertiary};
		}
	}

	.widget-plaque__allowance,
	.widget-plaque__average {
		&.widget-plaque {
			border-left-color: $theme-beta-quaternary;
		}
		.stat {
			color: $theme-beta-quaternary;
		}
	}

	.widget-plaque__approved {
		&.widget-plaque {
			border-left-color: $theme-beta-tertiary;
		}
		.stat {
			color: $theme-beta-tertiary;
		}
	}

	.widget-plaque__requested {
		&.widget-plaque {
			border-left-color: $theme-beta-quinary;
		}
		.stat {
			color: $theme-beta-quinary;
		}
	}

	.widget-plaque__remaining {
		&.widget-plaque {
			border-left-color: $theme-beta-secondary;
		}
		.stat {
			color: $theme-beta-secondary-text;
		}
	}

	.widget-plaque__difference {
		&.theme-beta-tertiary {
			&.widget-plaque {
				border-left-color: $theme-beta-tertiary;
			}
			.stat {
				color: $theme-beta-tertiary-dark;
			}
		}

		&.theme-beta-quinary {
			&.widget-plaque {
				border-left-color: $theme-beta-quinary;
			}
			.stat {
				color: $theme-beta-quinary-dark;
			}
		}
	}
}

.dashboard-personal-requests,
.dashboard-personal-sick {
	.history-stats {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		display: block !important;
	}

	.history-controls {
		flex-shrink: 0;
		flex-grow: 0;
		flex-basis: 390px;
	}

	.status-icon {
		cursor: help;

		.icon-requested {
			color: $theme-beta-primary;
		}
		.icon-approved {
			color: $theme-beta-tertiary-dark;
		}
		.icon-declined {
			color: $theme-beta-quinary;
		}
	}
}

.dashboard-personal-sick {
	.widget-plaque__requested {
		&.widget-plaque {
			border-left-color: $theme-beta-secondary !important;
		}
		.stat {
			color: $theme-beta-secondary-text !important;
		}
	}
}

.dashboard-personal-team_locations {
	.prop-map {
		border-radius: 0;
	}

	.prop-loading {
		bottom: 8px !important;
	}
}

.dashboard-company-suspended_jobs,
.dashboard-personal-suspended_jobs {
	.widget-content {
		display: flex;
		flex-direction: row;
		gap: 10px;

		.left {
			flex: 1 1 100%;
		}

		.right {
			display: flex;
			flex: 0 0 118px;

			.widget-plaque {
				border-left-color: $theme-beta-quinary;
				.stat {
					color: $theme-beta-quinary;
				}
			}
		}
	}
}

.dashboard-personal-diary {
	.widget-content {
		display: flex;
		flex-direction: column;
		height: 1px; /* Hack to get calendar height correct */

		.diary-container {
			height: 100%;
		}

		.mbsc-schedule-grid-scroll {
			height: auto !important;
			max-height: inherit !important;
		}

		.mbsc-schedule-all-day-wrapper-shadow:after {
			box-shadow: none;
		}

		.mbsc-schedule-event-hover {
			cursor: default;
		}
	}
}

.dashboard-company-holidays_this_month,
.dashboard-company-sickness_today {
	.widget-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.widget-plaque__team {
		border-left-color: $theme-beta-secondary;
		.stat {
			color: $theme-beta-secondary-text;
		}
	}

	.widget-plaque__company {
		border-left-color: $theme-beta-primary;
		.stat {
			color: $theme-beta-primary;
		}
	}
}

.dashboard-features-go_to_worker,
.dashboard-features-go_to_job {
	.widget-content {
		display: flex;
		flex-direction: row;
		justify-content: center;
		gap: 4px;
		padding-left: 16px;
		padding-right: 12px;

		.prop-form {
			flex-basis: 100%;
		}

		.icon-container {
			display: flex;
			align-items: center;

			svg {
				font-size: 2.5em;
				padding: 6px;
				border: 3px solid $theme-beta-light;
				border-radius: 50%;
				color: $theme-beta-tertiary-dark;
			}
		}

		.dropdown-container {
			display: flex;
			align-items: center;
			flex-basis: 100%;

			.react-select__value-container {
				margin-top: 0px !important;
			}
		}
	}
}

.dashboard-personal-holidays_approval {
	.widget-content {
		display: flex;
		flex-direction: row;
		height: 0; /* Allows the scrolling of the .right pane */
		gap: 10px;

		.left {
			display: flex;
			flex: 0 0 118px;

			.widget-plaque {
				border-left-color: $theme-beta-quinary;
				.stat {
					color: $theme-beta-quinary;
				}
			}
		}

		.right {
			display: flex;
			flex-direction: column;
			flex: 1;
			overflow-y: auto;

			.widget-list {
				svg {
					color: $theme-beta-tertiary-dark;
					padding-right: 6px;
				}
			}
		}
	}
}

.dashboard-personal-sales,
.dashboard-company-sales {
	$sales-gap: 10px;
	$sales-inner-panel-gap: 6px;
	$sales-fixed-width: 180px;

	&[class*='personal'] {
		.stats {
			border-color: $theme-beta-secondary;
		}
		.title-stat {
			color: $theme-beta-secondary-text;
		}
	}

	&[class*='company'] {
		.stats {
			border-color: $theme-beta-primary;
		}
		.title-stat {
			color: $theme-beta-primary;
		}
	}

	.widget-content {
		display: flex;
		flex-direction: row;
		gap: $sales-gap;
		height: 0;

		.widget-list {
			ion-item {
				--padding-start: 6px;
				--padding-end: 6px;
				--inner-padding-end: 0px;
				--min-height: 26px;

				ion-label {
					display: flex;
					gap: 5px;
					font-size: 0.85em;

					* {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					.label-left {
						flex: 0 1 65%;
					}

					.label-right {
						flex: 0 1 35%;
						text-align: right;
					}
				}
			}
		}

		.left {
			display: flex;
			flex: 1;
			flex-direction: column;
			gap: $sales-gap;

			.stats {
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				gap: $sales-inner-panel-gap;
				width: $sales-fixed-width;
				max-width: $sales-fixed-width;
				border-left-width: 4px;
				border-left-style: solid;

				ion-progress-bar {
					height: 8px;
					margin-top: 20px;
					--background: white;
					--progress-background: #{$theme-beta-tertiary};
				}
			}

			.chart {
				display: flex;
				flex-direction: row !important;
				flex: 1;
				width: 0;
				gap: $sales-inner-panel-gap;
				overflow: hidden !important;

				.title {
					flex-basis: 30px;
					align-items: flex-start;
				}

				.chart-left {
					flex: 1 1 0px;
					overflow: hidden; /* This makes the chart fully responsive */
				}

				.chart-right {
					display: flex;
					flex-direction: column;
					flex: 0 0 40%;
					max-width: 40%;

					.closed-won-container {
						flex: 1 1 100%;
						overflow-y: auto;

						ion-item {
							cursor: default;
						}
					}
				}
			}

			.panel {
				display: flex;
				flex-direction: row;
				gap: $sales-gap;
				height: 0;
			}

			.sub-panel {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				padding: 6px 8px;
				background-color: $theme-beta-light;
				overflow-y: auto;
			}

			.panel-a {
				flex-basis: 56%;
			}

			.panel-b {
				flex-basis: 44%;
				background-color: $theme-beta-light;

				.title-stat {
					flex: 0%;
				}

				.stat-vs-target {
					display: flex;
					flex: 1 1 50%;
					justify-content: center;
					align-items: center;

					svg {
						color: $theme-beta-quinary;
						font-size: 2.2em;
						margin-right: $margin-std;

						&.svt-positive {
							color: $theme-beta-tertiary;
						}
					}

					.svt-value {
						font-size: 0.9em;

						div {
							font-size: 1.8em;
							margin-bottom: -7px;
						}
					}
				}
			}
		}

		.right {
			display: flex;
			width: $sales-fixed-width;
			max-width: $sales-fixed-width;
			flex-direction: column;

			.panel-c {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				padding: 6px 8px;
				gap: $sales-inner-panel-gap;
				flex: 1;
				height: 0;
				background-color: $theme-beta-light;

				.title-stat {
					flex: 0 0 60px;
				}

				.open-deals-container {
					flex: 1 1 100%;
					overflow-y: auto;
				}

				.prop-link {
					display: flex;
				}
			}
		}

		/* Elements */
		.title {
			display: flex;
			flex: 0 0 1%;
			align-items: center;
			justify-content: center;
			font-size: 1em;
			letter-spacing: -0.02em;
		}

		.title-stat {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			font-size: 3em;
			line-height: 1em;
			flex: 100%;

			&.lower {
				flex: 60%;
			}
		}
	}

	.widget-footer {
		.worker-selection-container {
			display: flex;
			justify-content: end;
			flex-wrap: nowrap;

			.react-select-container {
				width: 214px !important;

				.react-select__value-container {
					.react-select__placeholder {
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
			.react-select__value-container {
				margin-top: 0px !important;
			}

			.icon-container {
				display: flex;
				align-items: center;

				svg {
					font-size: 1.6em;
					padding: 4px;
					border: 2px solid #dfe3e6;
					border-radius: 50%;
					color: $theme-beta-tertiary-dark;
				}
			}
		}
	}
}
