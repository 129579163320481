.ratesTable {
  th {
    width: 16%;
  }
  td {
    height: 3.5em;

    ion-col {
      padding: 0;
    }
  }
}
