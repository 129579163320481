@import './theme-defaults.scss';

.form-image-wrapper {
	&.prop-header-dim {
		width: calc($header-width + 2px);
		height: calc($header-height + 2px);
	}

	&.prop-footer-dim {
		width: calc($footer-width + 2px);
		height: calc($footer-height + 2px);
	}

	&.prop-client-logo-dim {
		width: calc($client-logo-width + 2px);
		height: calc($client-logo-height + 2px);
	}
}

img {
	&.prop-header-dim {
		width: $header-width;
		height: $header-height;
	}

	&.prop-footer-dim {
		width: $footer-width;
		height: $footer-height;
	}

	&.prop-client-logo-dim {
		width: $client-logo-width;
		height: $client-logo-height;
	}
}
