@import '../../External.scss';

.external-kpi-rating {
	.rating-icon-container {
		display: flex;
		justify-content: center;
		margin-top: 50px;

		.rating-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100px;
			height: 100px;
			border-radius: 100px;
			background: $theme-background-light;

			svg {
				color: $theme-std;
				font-size: 2.8em;
			}
		}
	}

	.rating-title {
		font-size: 2.8em;
		text-align: center;
		margin-top: 26px;
	}

	.rating-container {
		margin-top: $margin-large;
		padding: $padding-large;
		border-radius: $border-radius-small;
		background-color: $light-bg;

		.rc-text {
			margin: $margin-std 0;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		.rc-rating {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: $margin-large 0;
			margin-bottom: 0;
		}

		#focus-grabber {
			cursor: default !important;
		}
	}

	.footer-custom {
		margin-top: 25px !important;
	}
}
