.component-utilities-national_insurance_rates {
  label {
    display: block;
    margin-top: 9px;
    margin-right: 3px;
  }

  .prop-form-edit {
    width: 140px;
    margin-right: 30px;
  }

  .prop-form-date {
    width: 140px;
  }
}
