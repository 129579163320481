@import './theme-defaults.scss'; // Use defaults defined there
@import './effects.scss';

// Variables
$menuExpanded: 260px;
$menuIcons: 100px;
$contentOverlap: 10px;

* {
	@include scrollbars();
}

ion-content {
	--padding-bottom: 20px;
	--padding-start: 35px;
	--padding-end: 35px;
	--padding-top: 20px;
}

ion-header ion-toolbar {
	color: #fff;
	--ion-background-color: #{$theme-std};
}

ion-content.center::part(scroll) {
	display: flex;
	align-items: center;
	justify-content: center;
}

// External pages fill the screen with no left nav menu
ion-app.external-page {
	ion-router-outlet {
		width: 100% !important;
		margin: 0 !important;
		border: none !important;
		transition: none !important;

		ion-content {
			--background: white;
		}
	}
}

ion-content.center video,
ion-content.center img {
	max-width: 100%;
}

// Menus
#menu {
	height: 100%;
	position: fixed;
	background-color: $theme-background-std;
	color: $theme-font-contrast;

	ul {
		padding: #{$padding-std} 0px;
		font-size: 1.5rem;

		li {
			list-style-type: none;
			color: $theme-font-contrast;
			text-align: center;
		}

		li a {
			display: block;
			position: relative;
			padding: $padding-small 0;
			padding-bottom: $padding-large;
			border-right-width: 4px;
			border-right-style: solid;
			border-right-color: transparent;
		}

		li a .menu-link-title,
		li a .menu-link-title-exp {
			display: none;
			font-size: 10px;
			position: absolute;
			bottom: 7px;
			left: 0px;
			width: 88px;
			text-align: center;
		}

		li a .menu-link-title-exp {
			color: change-color($theme-std, $lightness: 60);
		}

		li a.active {
			background-color: change-color($theme-std, $lightness: 38);
			border-right-color: $theme-mid;
		}

		li {
			a.active,
			a:hover {
				.menu-link-title,
				.menu-link-title-exp {
					display: inline-block;
				}
			}
		}

		li {
			a:not(.active):hover {
				.menu-link-title {
					color: change-color($theme-std, $lightness: 70);
				}
			}
		}

		li a svg {
			margin-left: 4px;
		}

		li a.active:hover {
			background-color: change-color($theme-std, $lightness: 34);
		}

		li a:not(.active):hover {
			background-color: change-color($theme-std, $lightness: 45);
		}

		a {
			text-decoration: none;
			color: $theme-font-contrast;
		}
	}

	#human-facta,
	#human-facta-exp {
		margin-top: 16px;
		margin-bottom: 14px;
		padding: $padding-std;
		text-align: center;

		h1 {
			padding-bottom: $padding-small;
		}
	}

	#human-facta-exp {
		margin-top: $margin-std;
		margin-bottom: $margin-large;
	}

	ul#lower-menu {
		position: absolute;
		bottom: 0px;
		width: 100%;
	}

	.menu-chevron-container {
		width: 44px;
		height: 44px;
		border-radius: 22px;
		font-size: 16px;
		background-color: var(--ion-color-white);
		display: flex;
		align-items: center;
		position: absolute;
		right: -13px;
		top: -13px;
		cursor: pointer;

		svg {
			color: $theme-std;
			margin-left: 7px;
		}
	}

	&.menu-icons {
		width: $menuIcons;
		transition: $transition;

		#menu-wrapper {
			width: calc($menuIcons - $contentOverlap);
		}
	}

	&.menu-expanded {
		width: $menuExpanded;
		transition: $transition;

		#menu-wrapper {
			width: calc($menuExpanded - $contentOverlap);

			ul li:not(.menu-chevron) {
				text-align: left;

				a {
					padding-left: 21px;
				}

				svg {
					width: 40px;
					margin-right: $margin-large;
				}
			}
		}
	}
}

// Filter Menu
.filter-menu-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;

	.label {
		flex-grow: 1;
		text-align: left;
	}

	.colour-block-container {
		display: flex;
		justify-content: right;

		.colour-block {
			width: 20px;
			height: 20px;
			overflow: hidden;
			border: 1px solid #ffffff;
		}
	}

	svg {
		margin-right: 5px;
	}
}

.filter-menu-accordion {
	margin-top: 2px;

	ion-accordion ion-item[slot='header'] {
		--color: white;
		--ion-background-color: #{$theme-alt};
		box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
			0 1px 5px 0 rgba(0, 0, 0, 0.12);
		text-transform: uppercase;
		font-size: 14px;
		letter-spacing: 0.84px;
		font-weight: 500;
		margin: 2px 2px;
	}
	ion-accordion ion-item[slot='header'],
	ion-accordion ion-item[slot='header'] * {
		height: 36px;
		overflow: hidden;
	}

	.filter-menu-container:not(.button) {
		margin-top: 3px;
	}

	ion-accordion.accordion-expanded ion-item[slot='header'] {
		--color: black;
		--ion-background-color: #{$theme-mid};
	}

	ion-accordion .ion-accordion-toggle-icon {
		display: none;
	}
}

// Layout
ion-router-outlet {
	height: 100%;
	background: $theme-background-light;
	border-left: 8px solid var(--ion-color-white);

	&.router-narrow {
		width: calc(100% - ($menuExpanded - $contentOverlap));
		margin-left: calc($menuExpanded - $contentOverlap);
		transition: $transition;
	}

	&.router-expanded {
		width: calc(100% - ($menuIcons - $contentOverlap));
		margin-left: calc($menuIcons - $contentOverlap);
		transition: $transition;
	}
}

#content-wrapper {
	padding: $padding-std;
}

.title-bar {
	margin-top: 12px;
	margin-bottom: 17px;
}

.title-bar .top-left-container {
	display: flex;
	flex-flow: row-reverse;
	align-items: center;
	height: 40px;
}

.hf-page-title {
	color: $theme-std;
	font-weight: bold;
	line-height: 2rem;
}

.title-bar-logo {
	margin-right: 24px;
}

.title-backbutton {
	margin: 0 18px 0 0;
	font-size: 15px;
	--border-radius: #{$border-radius-large} !important;
	--ion-color-contrast: var(--ion-color-secondary) !important;
}

.title-user-menu {
	z-index: 1000;
	position: absolute;
	top: 50px;
	right: $margin-std;
	min-width: 260px;
	background-color: var(--ion-color-white);
	border-radius: #{$border-radius-large};
	box-shadow: 0 8px 9px -4px rgba(50, 50, 50, 0.4);

	.user-menu-header {
		width: 100%;
		padding: $padding-small 30px;
		color: var(--ion-color-white);
		border-radius: inherit;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		background-color: $theme-mid;
		text-align: center;

		p {
			margin-bottom: 0;
			font-size: 14px;
		}
	}

	.user-menu-inner {
		padding: $padding-small;
	}
}

// Avatar: title bar
.title-avatar {
	width: 40px;
	height: 40px;
	color: var(--ion-color-white);
	--ion-color-contrast: var(--ion-color-white) !important;
	font-size: 20px;
	--box-shadow: unset;
}

// Avatar: calendars
.resource-avatar-container {
	display: flex;
	flex-direction: row;
	align-items: start;
	gap: 6px;
	position: relative;

	.icon-self-employed {
		position: absolute;
		top: -4px;
		left: 24px;
		color: $theme-alt;
		border-radius: 50%;
		border: 1px solid white;
		background-color: white;
		font-size: 0.9em;
	}

	.resource-avatar-outer {
		margin-top: -4px;
		margin-left: -4px;
		padding: 2px;
		border-radius: 50%;
		background-color: transparent;
		border: 2px solid transparent;

		&.self-employed {
			/* border-color: var(--ion-color-self-employed); */
		}
	}

	.resource-avatar {
		display: flex;
		justify-content: center;
		flex-grow: 0;
		flex-shrink: 0;
		width: 32px;
		height: 32px;
		line-height: 32px;
		border-radius: 50%;
		overflow: hidden;
		font-weight: normal;
		color: var(--ion-color-white);
		background-color: var(--ion-color-secondary);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	.resource-sub-container {
		display: flex;
		flex-direction: column;
		line-height: 18px;

		.resource-avatar-title {
			font-weight: bold;
		}

		p {
			font-weight: normal;
			color: var(--ion-color-medium);
			margin: 0;
			padding: 0;
		}
	}
}

// Icons & Badges
.button-icon {
	margin-right: $margin-std;
}

.submenu-badge {
	font-size: 1rem;
	line-height: 1rem;
	position: absolute;
	left: 6px;
	margin-top: calc(0.5rem - (1rem / 2) - 2px);
}

svg.submenu-badge {
	left: 5px;
}

.fl-badge {
	position: absolute;
	right: -1em;
	top: -1em;
}

.badge {
	min-width: 20px;
	border-radius: 50%;
	min-height: 20px;
	font-size: 0.6rem;
	line-height: 0.65rem;
}

.badge-number {
	background: $theme-std;
	padding: 5px;
	margin-top: 2px;
	margin-left: 5px;
	contain: content;
}

.badge-color {
	padding: $padding-xsmall;
	margin-top: 2px !important;
	margin-left: $margin-small !important;
}

input {
	background-color: var(--ion-color-white);
	color: var(--ion-color-dark);
}

// Forms
.prop-form {
	font-size: 0.9rem;

	ion-label {
		font-size: 0.9rem;
		line-height: 33px;
		color: #222;

		ul {
			margin: 0;
		}
		ul li {
			list-style-type: disc;
			line-height: 1.2em;
			font-size: 0.9rem;
		}
	}

	.is-mandatory {
		color: $theme-accent-dark;

		.field-title:after {
			content: ' *';
		}
	}

	ion-input,
	.fake-ion-input {
		border: 1px solid var(--ion-color-medium);
		border-radius: $border-radius-medium;
		outline: none;
		height: 34px;
		min-height: 34px;

		input {
			padding: $padding-xsmall !important;
		}

		&.is-valid {
			border: 1px solid var(--ion-color-success);
			box-shadow: $field-valid;
		}

		&.is-invalid {
			border: 1px solid var(--ion-color-danger);
			box-shadow: $field-invalid;
		}

		&.has-focus {
			border: 1px solid $theme-alt;
			box-shadow: $field-focus;
		}
	}

	p {
		margin-bottom: 0px;
		line-height: 33px;
	}

	.password-container {
		padding: 3px 4px 0 0;
	}

	.info-box-border {
		border: 1px solid var(--ion-color-medium);
		border-radius: #{$border-radius-medium};
		padding-left: 6px;
		padding-right: 6px;
		min-height: 2.2em;
		margin-top: -2px;
	}

	.info-box-show-background {
		color: #666;
		background-color: rgb(255 255 204 / 20%);
	}

	.info-box-danger {
		color: #111;
		background-color: rgb(255 44 0 / 20%);
	}

	.info-box-multi-line {
		overflow-y: scroll;
	}

	// React-select
	.react-select-container {
		.react-select__control {
			height: 34px;
			min-height: 34px;
			border-color: #9ea0aa;
			border-radius: #{$border-radius-medium};
		}
		.react-select__value-container {
			margin-left: -4px;
		}
	}

	// React-select - dropdown, dropdownasync
	.react-select-dropdown-multi,
	.react-select-dropdownasync-multi {
		.react-select__control {
			height: auto !important;
			min-height: 34px;
		}
		.react-select__value-container {
			margin-top: 1px;
		}
	}

	// React-select - autocomplete
	.react-select-autocomplete-multi {
		.react-select__control {
			height: auto !important;
			min-height: 34px;
		}
		.react-select__value-container {
			margin-top: 1px !important;
			margin-bottom: -2px;
		}
		.react-select__indicators {
			margin-bottom: -1px;
		}
	}

	.prop-form-file-upload-controls-container {
		display: flex;
		flex-direction: row;
		gap: $margin-std;

		.form-info-box-container {
			flex: 1 1 100%;

			.info-box-border {
				margin: 0 !important;
			}
		}

		ion-button {
			margin: 0;
		}
	}
}

// Mobiscroll select
.prop-mobi-select-inline {
	--font-size: 0.9em;

	.mbsc-select-scroller-inline {
		border: 1px solid #9ea0aa;
		border-radius: #{$border-radius-medium};

		.mbsc-scroller-wheel-item {
			font-size: var(--font-size);
		}

		.mbsc-wheel-checkmark:after {
			margin-top: 2px;
		}
	}

	.mbsc-select-empty-text {
		font-size: 1em;
	}

	.mbsc-hover {
		border-radius: 0;
	}

	.prop-mobi-select-group-heading {
		font-size: 0.9em;
		line-height: 3.8em;
		color: #999999;
		font-weight: normal;
		text-transform: uppercase;
	}

	// Filter
	&.filter {
		.mbsc-select-scroller-inline {
			border-top-color: #cfd2df;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}

		.mbsc-select-filter-cont {
			padding: 5px 5px 6px 5px;
			border-top-left-radius: #{$border-radius-medium};
			border-top-right-radius: #{$border-radius-medium};
			border: 1px solid #9ea0aa;
			border-bottom: none;

			.mbsc-select-filter-input {
				font-size: var(--font-size);
			}
		}
	}
}

.form-title {
	font-weight: bold;
	margin-top: $margin-std;
	width: 100%;
	clear: both;

	&.style-alpha {
		width: calc(100% + 20px);
		margin-left: -10px;
		margin-right: -10px;
		font-weight: normal;
		padding: 18px 14px;
		background-color: #f4f6f8;
		border-bottom: 1px solid #e0e0e0;
	}

	&.style-beta {
		font-weight: normal;
	}
}

.utility-form-padding {
	margin-left: $margin-large;
	margin-right: $margin-large;
	margin-bottom: $margin-large;
}

.section-break {
	border: none;
	display: block;
	width: 100%;
	clear: both;
}

ion-checkbox.lowered {
	margin-bottom: -4px;
	margin-right: $margin-std;
}

.form-image-wrapper {
	border: 1px solid var(--ion-color-medium);
}

.form-card {
	overflow: visible;
}

.form-card-header {
	font-weight: bold;
	background: var(--ion-color-light);
	margin-bottom: $margin-std;
}

.ion-number-input {
	// Keep the up/down arrows within the input field area
	overflow: hidden;

	&.hide-number-spinner ::-webkit-outer-spin-button,
	&.hide-number-spinner ::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&.hide-number-spinner input[type='number'] {
		-webkit-appearance: textfield;
		-moz-appearance: textfield;
		appearance: textfield;
	}
}

.upload-button {
	margin-left: calc(50% - 75px);
	margin-top: $margin-std;
}

.upload-button-wrapper {
	display: block;
	width: 100%;
	clear: both;
	margin-top: $margin-std;
	margin-bottom: $margin-std;
	height: calc(2.1em + $margin-std);
	display: flex;
	align-items: center;

	.upload-button {
		margin: 0px;
	}

	.form-button-caption {
		font-size: 0.8rem;
		margin-left: $margin-std;
		flex: 1 1 0;
	}
}

.color-input-picker-label {
	cursor: pointer;
	border: 1px solid var(--ion-color-medium);
	border-radius: $border-radius-medium;

	.colour-input-picker {
		border: 0;
		padding: 0;
		visibility: hidden;
	}
}

.color-picker {
	display: inline-block;
	margin-right: 25px;

	h5 {
		margin-bottom: 20px;
	}

	.block-picker {
		border: 1px solid var(--ion-color-medium);
		box-shadow: $z-1 !important;
	}
}

.info-box {
	width: 100%;
	background: var(--ion-color-light);
	border: 1px solid var(--ion-color-medium);
	padding: $padding-std;
	border-radius: $border-radius-medium;
	min-height: 44px;
	margin-top: $margin-std;
	box-shadow: $z-1;

	span {
		font-size: 0.9rem;
		line-height: 14px;
		display: block;
	}
}

@supports (height: fit-content) {
	.info-box {
		height: fit-content;
	}
}

ion-toggle {
	--background-checked: var(--ion-color-mid-light-grey);
}

.front-cover-image {
	width: 80%;
	border: 1px solid var(--ion-color-medium);
}

// PDFs
.react-pdf__Document {
	max-height: calc(100vh - 300px);
	max-width: 50%;
	overflow: auto;
	@include scrollbars();
}

// Material Table overrides
.MuiPaper-root {
	box-shadow: none !important;
	border: none;
	border-radius: 0px;

	.MuiTable-root tfoot tr td {
		border: none;
	}
}

.MuiTable-root ion-input {
	background-color: white;
}

.MuiTableFooter-root.table-footer-grand-total {
	background-color: $theme-light;

	* {
		font-size: 1em;
		font-weight: 600;
	}
}

// Overlays
.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 9000;
	cursor: pointer;
}

.overlay-container {
	position: absolute;
	top: 60px;
	width: calc(100% - 70px);
	background-color: #fff;
	border-radius: $border-radius-medium;
	z-index: 9999;

	.overlay-title {
		color: #fff;
		padding: 12px 16px;
		background-color: $theme-mid;
		margin-bottom: $margin-large;
		border-top-left-radius: $border-radius-medium;
		border-top-right-radius: $border-radius-medium;

		ion-button {
			color: white;
			margin: 0;
			margin-top: -18px;
			margin-bottom: -6px;
			font-size: 16px;
			--background: transparent;
			--box-shadow: none;
		}
	}

	.overlay-content {
		margin-bottom: $margin-large;
		width: 100%;
		min-height: 400px;
		max-height: calc(100vh - 180px);
		overflow-y: auto;
		padding: $padding-std;
		padding-top: 0;
	}

	.overlay-footer {
		height: 50px;
		max-height: 50px;
		overflow: hidden;
		padding: 0 10px;
		border-bottom-left-radius: $border-radius-medium;
		border-bottom-right-radius: $border-radius-medium;
	}
}

// Utilities
#utilities-sections {
	.utilities-section {
		margin-bottom: 24px;
		background-color: var(--ion-color-white);

		.utilities-title {
			color: $theme-std;
			margin-bottom: 2px;
			font-weight: bold;
		}
	}

	.utilities-item {
		border-radius: $border-radius-medium;
		width: 100%;
		height: 100%;
		padding: $padding-std;
		padding-left: $padding-xsmall;
		background-color: transparent;
		font-size: 1rem;
		cursor: pointer;
		display: flex;

		h4 {
			width: calc(100% - 34px);
			margin-bottom: 0;
			font-size: 1rem;
		}

		svg {
			width: 30px;
			padding-right: $padding-xsmall;
		}
	}

	.utilities-item:hover {
		background-color: #{$theme-light};
	}
}

.propeller-content-card {
	background: var(--ion-color-white);
	padding: 20px 24px 20px 24px;
	color: $theme-font-color;
	overflow: visible;
	box-shadow: none;
	border-radius: 0;
	margin: 0;
}

.card-title {
	font-size: 1.1rem;
	font-weight: bold;
	margin-bottom: $margin-std;
}

.submenu-item-title {
	font-weight: 600;
	padding: 6px 0 0 0;
}

.utilities-submenu {
	$border-style: 1px solid #f3f3f3;
	padding-left: 0px;
	margin-left: -35px;

	.utilities-submenu-item:first-child {
		border-top: $border-style;
	}

	.utilities-submenu-item {
		width: 100%;
		height: 100%;
		padding: 12px 12px;
		padding-left: 32px;
		font-size: 1rem;
		cursor: pointer;
		color: $theme-std;
		position: relative;
		border-bottom: $border-style;

		&:not(.active):hover {
			background-color: change-color($theme-light-highlight, $lightness: 91);
		}

		&.active {
			font-weight: bold;
			background-color: $theme-light-highlight;

			&:hover {
				background-color: change-color($theme-light-highlight, $lightness: 86);
			}
		}

		span {
			display: block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding-left: 8px;
		}

		.utilities-submenu-chevron {
			position: absolute;
			display: none;
			top: 16px;
			right: 6px;
		}

		&.active .utilities-submenu-chevron {
			display: block;
		}
	}
}

#card-second-col {
	table thead tr th {
		padding-top: 0px;
	}
}

.table-card {
	background: $theme-font-contrast;
	padding: $padding-std;
	overflow: visible;
	margin: 0;
}

.time-bar-buttons {
	background: var(--ion-color-light);
	border-radius: $border-radius-medium;
	width: -moz-fit-content;
	width: fit-content;

	.selected {
		background: var(--ion-color-primary);
		border-radius: $border-radius-small;

		&::part(native) {
			color: $theme-font-contrast;
		}
	}
}

.table-buttons {
	margin-top: -50px;
	margin-bottom: $margin-std;

	.css-b62m3t-container {
		padding-left: $padding-std;
		background-color: $theme-font-contrast;
		height: 40px;
		padding-top: 1px;
	}
}

.table-card .MuiIconButton-root .fa-x {
	color: var(--ion-color-danger);
}

.all-borders {
	border: 1px solid var(--ion-color-medium);
}

.border-radius-large {
	border-radius: $border-radius-medium;
}

.security-block,
.security-option {
	cursor: pointer;
}

li.security-block span {
	border: 1px solid var(--ion-color-medium);
	padding: $padding-std;
	display: block;
	margin-right: $margin-large;
	text-align: center;
	border-radius: $border-radius-small;
}

li.security-block {
	.security-success {
		background: var(--ion-color-success);
		color: $theme-font-contrast;
	}

	.security-primary {
		background: var(--ion-color-primary);
		color: $theme-font-contrast;
	}

	.security-warning {
		background: var(--ion-color-warning);
		color: $theme-font-color;
	}

	.security-danger {
		background: var(--ion-color-danger);
		color: $theme-font-contrast;
	}

	ul {
		padding-left: 0px;
		position: relative;
		z-index: 100;

		li {
			border: 1px solid var(--ion-color-medium);
			border-bottom: none;
			padding: $padding-std;
			display: block;
			text-align: center;
			z-index: 100;
			box-shadow: $z-4;

			&:first-child {
				border-top-left-radius: $border-radius-small;
				border-top-right-radius: $border-radius-small;
			}

			&:last-child {
				border-bottom-left-radius: $border-radius-small;
				border-bottom-right-radius: $border-radius-small;
				border-bottom: 1px solid var(--ion-color-medium);
			}
		}
	}
}

.security-blocks-wrapper {
	padding-left: 0px;
	margin-bottom: 0;
	display: flex;

	li {
		width: 140px;
		height: 75px;
		margin-right: $margin-large;
		margin-top: $margin-large;

		span {
			width: 140px;
			height: 75px;
			display: table-cell;
			vertical-align: middle;
			z-index: 1;
		}

		ul li {
			margin-top: 0px;
			height: 45px;
			line-height: 15px;
			z-index: 100;
		}
	}
}

.security-block-small ul {
	display: flex;
}
.security-small {
	padding: $padding-xsmall;
	height: 50px;
	width: 150px;

	span {
		padding: $padding-xsmall;
		height: 100%;
		width: 100%;
		line-height: $margin-std;
	}
}

.prop-datepicker .mbsc-material.mbsc-textfield-wrapper {
	margin: 0;
}

// Hide the clear button from the time picker
.mbsc-timepicker-no-clear-buttons .mbsc-range-label-clear {
	display: none !important;
}
.form-time-row * {
	cursor: pointer !important;
}

.table-no-toolbar .MuiToolbar-root {
	display: none;
}

.prop-table {
	text-align: center;
}

.prop-border-top {
	border-top: 1px solid rgb(207, 207, 207) !important;
}

.card-footer {
	background: var(--ion-color-light);
	border-top: 1px solid var(--ion-color-medium);
	padding: $padding-small;
	padding-top: $padding-small;
	min-height: 54px;
	display: flex;

	* {
		flex: 1 1 0;
	}
}

// Modules
.module-panels {
	margin-top: -5px;
	display: flex;
}

.landing-card {
	box-shadow: none;
	height: 100%;

	h3 {
		color: $theme-std;
		font-weight: bold;
		padding: 14px;
		position: relative;
		margin-bottom: $margin-large;
		background-color: $theme-light;
		border-radius: var(--ion-border-radius-medium);
	}

	ul {
		padding-left: $padding-std;
		margin-bottom: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: start;
		gap: $padding-small;
		min-height: 130px;
	}

	li {
		color: var(--ion-color-dark);
		flex-basis: 100%;
	}

	svg {
		color: $theme-std;
		width: 30px;
		padding-right: $padding-xsmall;
	}

	&:hover {
		box-shadow: $google-box-shadow;
	}
}

.sub-nav-tabs {
	display: flex;
	margin-bottom: $margin-std;

	ion-col {
		font-weight: 400;
		border-bottom: 1px solid #b2b2b2;
		background: $theme-font-contrast;
		text-align: center;
		display: table-cell;
		vertical-align: middle;
		border-right: none;
		cursor: pointer;
	}

	ion-col.disabled {
		cursor: default;
	}

	ion-col:not(.disabled):hover {
		background-color: #fafafa;
	}

	ion-col.active {
		color: $theme-std;
		font-weight: bold;
		border-bottom: 2px solid $theme-mid;
	}
}

.staff-image {
	max-width: 100%;
	border-radius: $border-radius-medium;
	border: 1px solid var(--ion-color-medium);
	box-shadow: $z-1;
	padding: $padding-std;
}

.table-sections thead.MuiTableHead-root {
	background: var(--ion-color-light);
}

.prop-form-date {
	border: 1px solid var(--ion-color-medium);
	border-radius: $border-radius-medium;
	width: 100%;
	padding: calc($padding-xsmall - 1px);
	padding-bottom: 5px;
	margin-top: -2px;

	&::placeholder {
		color: rgba(0, 0, 0, 0.5);
	}

	&.is-invalid {
		border: 1px solid var(--ion-color-danger);
		box-shadow: $field-invalid;
	}
}

.css-b62m3t-container {
	z-index: 10;
}

.css-26l3qy-menu {
	z-index: 100;
	background: $theme-font-contrast;
}

#filter-text-box-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

#filter-text-box {
	width: 100%;
	border: 1px solid var(--ion-color-medium);
	border-radius: $border-radius-small;
	font-size: 1rem;
}

#filter-text-box-icon {
	display: flex;
	align-items: center;
	font-size: 1.1rem;
	margin-right: 7px;
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}

.info-box-buttons .info-box {
	cursor: pointer;
}

.ag-cell {
	.css-qc6sy-singleValue,
	.css-14el2xx-placeholder {
		height: 32px;
		line-height: 32px;
		z-index: 10;
	}

	.css-6j8wv5-Input {
		height: 32px;
		margin: 0;
		z-index: 10;

		input {
			height: 28px;
			line-height: 28px;
			z-index: 10;
		}
	}
}

.ag-center-cols-viewport {
	scrollbar-gutter: stable;
}

.undo-icon {
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	margin-top: 14px;
	margin-left: -$margin-large;
}

.add-new-data-icon {
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	margin-top: 15px;
	margin-right: -20px;
	font-size: 18px;
	z-index: 9000;
}

.event-color-c {
	height: 40px;
	margin-bottom: $margin-std;
	display: flex;

	.event-color-label {
		padding: $padding-std;
		padding-top: $padding-small;
		margin-left: 5px;
		width: 100px;
	}

	.event-color {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		border: 1px solid var(--ion-color-medium);
		box-shadow: $z-2;
	}
}

.crud-color-row {
	display: flex;
	justify-content: center;
	margin: 5px;

	.crud-color-c {
		padding: 0px;
		margin: 4px;

		.crud-color {
			position: relative;
			min-width: 46px;
			min-height: 46px;
			margin: 2px;
			cursor: pointer;
			border-radius: 23px;
			background: var(--ion-color-secondary);
		}

		&.mbsc-icon.mbsc-font-icon.mbsc-icon-material-check {
			display: inline-block;
			vertical-align: middle;
			width: 1.5em;
			height: 1.5em;
			line-height: 1.5em;
			text-align: center;
			-moz-box-flex: 0;
			-webkit-box-flex: 0;
			flex: 0 0 auto;
		}
	}
}

.crud-color-c.selected {
	border: 2px solid var(--ion-color-dark);
	border-radius: 50%;
}

.crud-color-c.selected .crud-color:before {
	display: block;
}

.crud-color:before {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -$margin-std;
	margin-left: -$margin-std;
	color: var(--ion-color-light);
	font-size: 20px;
	text-shadow: 0 0 3px var(--ion-color-dark);
	display: none;
}

.mbsc-icon-material-check:before {
	content: '\ea72';
}

.mbsc-font-icon:before {
	font-family: Mobiscroll;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.message-image {
	width: 60px;
	height: 60px;
	background: var(--ion-color-success);
	border-radius: 50%;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	margin-top: 22px;
	box-shadow: $z-1;
}

.message-preview {
	border: 1px solid var(--ion-color-medium);
	border-radius: $border-radius-medium;
	min-height: 60px;
	padding: $padding-std;
	box-shadow: $z-1;
	cursor: pointer;
	display: flex;
	flex-direction: row-reverse;
}

.message-time {
	position: relative;
	display: block;
	margin: 0;
}

.message-content {
	flex: 1 1 0;
}

ion-textarea {
	border: 1px solid var(--ion-color-medium);
	border-radius: $border-radius-small;
	margin-top: 0px !important;
}

.ag-body-horizontal-scroll-viewport {
	@include scrollbarsHorizontal();
}

.prop-map {
	flex: 1;
	height: 100%;
	border: 1px solid var(--ion-color-mid-light-grey);
	border-radius: #{$border-radius-large};
	overflow: hidden;

	.gmnoprint[role='menubar'],
	.gm-fullscreen-control,
	.gmnoprint:not([role='menubar']) > button,
	.gmnoprint:not([role='menubar']) > div {
		border-radius: #{$border-radius-large} !important;
		overflow: hidden;
		box-shadow: #{$google-box-shadow} !important;
	}

	.gmnoprint[role='menubar'] {
		right: 70px !important;
		bottom: 15px !important;
	}
}

// Mobiscroll calendar over-rides
.working-hours-calendar .mbsc-schedule-header-day {
	display: none;
}

.working-hours-calendar
	.mbsc-material.mbsc-schedule-header-item-large
	.mbsc-schedule-header-dayname {
	font-size: 0.8rem;
}

.working-hours-calendar .mbsc-calendar-wrapper {
	display: none;
}

.working-hours-calendar .mbsc-schedule-all-day-cont {
	display: none;
}

// Hide the 'All-day' range text
.event-source-holiday-chart {
	.mbsc-schedule-event-range {
		display: none;
	}
}

// Calendar cell backgrounds - note the SVG fills
.bg-cal-cell {
	background-repeat: no-repeat !important;
	background-position: 3px 3px !important;
}

.bg-cal-cell-home {
	background-image: url('data:image/svg+xml;utf8,<svg fill="%23888888" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"/></svg>');
	background-size: 17px;
}

.bg-cal-cell-office {
	background-image: url('data:image/svg+xml;utf8,<svg fill="%23888888" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16z"/></svg>');
	background-size: 11px;
	background-position: 4px 3px;
}

.bg-cal-cell-roaming {
	background-image: url('data:image/svg+xml;utf8,<svg fill="%23888888" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M135.2 117.4L109.1 192H402.9l-26.1-74.6C372.3 104.6 360.2 96 346.6 96H165.4c-13.6 0-25.7 8.6-30.2 21.4zM39.6 196.8L74.8 96.3C88.3 57.8 124.6 32 165.4 32H346.6c40.8 0 77.1 25.8 90.6 64.3l35.2 100.5c23.2 9.6 39.6 32.5 39.6 59.2V400v48c0 17.7-14.3 32-32 32H448c-17.7 0-32-14.3-32-32V400H96v48c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V400 256c0-26.7 16.4-49.6 39.6-59.2zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>');
	background-size: 16px;
}

.bg-cal-cell-schedule {
	background-image: url('data:image/svg+xml;utf8,<svg fill="%23888888" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z"/></svg>');
	background-size: 14px;
}

.bg-cal-cell-not-working-day {
	background-image: url('data:image/svg+xml;utf8,<svg fill="%23c64a4a" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg>') !important;
	background-size: 15px !important;
	background-color: transparent !important;
	z-index: 1;
}

.bg-cal-cell-today {
	background-color: rgba(176, 211, 236, 0.4);
}

.bg-cal-cell-weekend {
	background-color: rgba(220, 220, 220, 0.4);
}

.bg-cal-cell-bank-holiday {
	@include background-opacity($theme-beta-quaternary-dark, 0.16);
}

// Hides the calendar context menu's select's down-arrow
.calendar-context-menu-input {
	display: none;
}

.readonly {
	.mbsc-calendar-cell {
		cursor: default;
	}
	.mbsc-material.mbsc-hover .mbsc-calendar-cell-text {
		background-color: inherit;
	}
	.mbsc-material.mbsc-selected .mbsc-calendar-cell-text {
		color: inherit;
		background-color: inherit;
		border-color: inherit;
	}
	.mbsc-material.mbsc-selected .mbsc-calendar-today {
		color: #1a73e8;
	}
}

.mbsc-schedule-all-day-wrapper {
	@include scrollbars();
}

.mbsc-schedule-grid-scroll {
	@include scrollbars();
	max-height: 60vh;
}

.mbsc-prop-custom-header ion-segment {
	margin: -5px 0 7px 0;
}

.scrollbar-padding {
	width: 12px;
}

.border-box {
	border: 1px solid var(--ion-color-medium);
	border-radius: $border-radius-medium;
}

.shift-up {
	margin-top: -38px;
}

.pointer {
	cursor: pointer;
}

.cursor-default {
	cursor: default !important;
}

.prop-timeline {
	.mbsc-timeline-grid-scroll {
		@include scrollbarsHorizontal();
	}
}

tr.danger {
	background-color: var(--ion-color-danger);

	td {
		color: $theme-font-contrast;
	}
}

.map-outer {
	height: 800px;
	max-height: 80vh;
}

.drop-tasks-wrapper {
	margin-top: 62px;

	.external-drop-task {
		padding: $padding-small $padding-std;
		border-radius: $border-radius-medium;
		margin-bottom: $margin-std;
		color: $theme-font-contrast;
		box-shadow: $z-2;
	}
}

.mbsc-toggle {
	height: 40px;
	padding: 0px $padding-large;
	display: flex;
	flex-wrap: wrap;

	h5 {
		width: 100%;
	}

	.mbsc-switch {
		width: 30px;

		.mbsc-switch-track:not(.mbsc-checked) {
			background-color: rgba(51, 91, 163, 0.5);
			.mbsc-switch-handle {
				background-color: var(--ion-color-primary);
			}
		}

		.mbsc-checked {
			background-color: rgba(255, 183, 56, 0.5);
			.mbsc-switch-handle {
				background-color: var(--ion-color-warning);
			}
		}
	}
}

.mbsc-popup-content {
	@include scrollbars();
}

.toggle-yes-no {
	height: 40px;
	padding: 0px $padding-large;
	display: flex;
	flex-wrap: wrap;

	h5 {
		width: 100%;
	}

	.mbsc-switch {
		width: 30px;

		.mbsc-switch-track:not(.mbsc-checked) {
			background-color: rgba(198, 74, 74, 0.5);
			.mbsc-switch-handle {
				background-color: var(--ion-color-danger);
			}
		}

		.mbsc-checked {
			background-color: rgba(91, 163, 51, 0.5);
			.mbsc-switch-handle {
				background-color: var(--ion-color-success);
			}
		}
	}
}

.filter-data-table {
	ion-input {
		max-height: 34px;
		margin-top: -2px;
	}
}

.info-box.selected {
	background-color: var(--ion-color-primary);
	color: $theme-font-contrast;
}

.info-select {
	padding: 3px $padding-std;

	.css-26l3qy-menu {
		color: $theme-font-color;
	}
}

.tree-card {
	margin: 0;
	padding: $padding-xsmall;
	text-align: center;

	h5 {
		font-weight: bold;
	}

	p {
		margin-bottom: 0px;
	}

	&.card-success {
		cursor: pointer;
	}
}

// Auth forms
.form-auth {
	width: 500px;
	margin: 0 auto;

	.card {
		border: none;
		box-shadow: 0 8px 9px -4px rgba(50, 50, 50, 0.4);
	}
	.form-inner {
		background-color: var(--ion-color-white);
	}
	.text-center.mb80 {
		margin-bottom: 80px;
	}
	label {
		font-size: 0.8rem;
	}
	.btn {
		padding: $padding-small;
		font-size: 0.8rem;
		font-weight: bold;
		border: none;
		box-shadow: 0 8px 9px -4px rgb(59 113 202 / 30%), 0 4px 18px 0 rgb(59 113 202 / 20%);
	}
	.code-digits {
		display: flex;
		flex-direction: row;
		gap: 12px;
	}
	.code-digit {
		text-align: center;
		--padding-end: #{$padding-xsmall};
		--padding-start: #{$padding-xsmall};
	}
	.buttons-2fa {
		display: flex;
		gap: 14px;
	}
	a:link,
	a:visited {
		color: $theme-alt;
	}
	a:hover,
	a:active {
		color: black;
	}
}

// General link
.prop-link:link,
.prop-link:visited {
	color: black;
}
.prop-link:hover,
.prop-link:active {
	color: $theme-std;
}
.prop-link.dotted {
	display: inline-block;
	border-bottom: 1px dotted $lightGrey;
}

// Authenticating message
.authenticating-message {
	height: 100vh;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.fa-spin {
		font-size: 3rem;
		margin-bottom: 16px;
	}
}

// UI
.prop-loading {
	width: 300px;
	height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;

	.prop-loading-overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: #ffffff;
		opacity: 0.5;

		&.fixed {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}

		&.no-background {
			background-color: transparent;
		}
	}

	svg {
		width: 90px;
		height: 90px;
		z-index: 9999;
	}
}

.password-display {
	display: flex;
	span {
		flex: 1;
	}
	ion-button {
		width: 38px;
	}
}

.react-select__indicator {
	padding: 7px !important;
}
.react-select__value-container {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	top: -1px;
}
.react-select__multi-value__label {
	padding-bottom: 2px !important;
	padding-top: 4px !important;
}

ion-button,
.sc-ion-buttons-md-s ion-button,
.react-select__control {
	height: 34px !important;
	min-height: 34px !important;
	--border-radius: #{$border-radius-medium};
}

.react-select-container.in-cell {
	margin-top: 3px;
}

ion-button.icon-only {
	font-size: 1.2rem;
}

ion-button.round {
	--border-radius: 50%;
	--padding-top: #{$padding-xsmall};
	--padding-bottom: #{$padding-xsmall};
	--padding-start: calc(#{$padding-xsmall} + 3px);
	--padding-end: calc(#{$padding-xsmall} + 3px);
}

ion-toolbar {
	ion-buttons::slotted([slot='end']) {
		margin-right: unset;
		margin-inline-end: 4px;
	}
}
ion-popover {
	ion-list :last-child {
		--border-color: #{$theme-background-light};
	}
}

ion-searchbar {
	input[type='search']::-webkit-search-decoration,
	input[type='search']::-webkit-search-cancel-button,
	input[type='search']::-webkit-search-results-button,
	input[type='search']::-webkit-search-results-decoration {
		-webkit-appearance: none;
	}
}

ion-chip.ion-chip-grey {
	--background: #dddddd;
	--color: #919191;
}

ion-chip.ion-chip-success {
	--background: #d1e0d1;
	--color: #52932e;
}

ion-chip.ion-chip-danger {
	--background: #e2d1d3;
	--color: #ba3b3b;
}

.sc-ion-searchbar-md-h {
	padding: 0;
	--border-radius: #{$border-radius-medium};
	text-align: start;
}

.searchbar-input.sc-ion-searchbar-md {
	border: 1px solid var(--ion-color-medium);
	border-radius: $border-radius-medium;
	outline: none;
	height: 34px;
	min-height: 34px;
	background-color: var(--ion-color-white);
	color: var(--ion-color-grey);
	box-shadow: none;
}

.searchbar-search-icon.sc-ion-searchbar-md {
	top: 7px;
}

// Icons
.icon-grid,
.icon-table {
	line-height: 2.58rem;
	padding-left: 6px;
	padding-right: 6px;
	text-align: center;
}

// Buttons in a grid
.button-small-grid ion-button.button-small {
	margin: -12px 0 0 0;
}

// AG Grid Overrides
.ag-row:not(.ag-row-hover, .ag-row-selected) {
	.ag-cell.highlight-row {
		background-color: #ffffcc;
		border-bottom-color: #ccc;
	}
}
.ag-row:not(.ag-row-hover, .ag-row-selected) {
	.ag-cell.danger-row {
		background-color: rgba(255, 44, 0, 0.08);
	}
}
.ag-cell.danger-row {
	color: #f00;
}
.ag-cell .undo-icon {
	margin-left: -12px;
}
.ag-row-pinned {
	font-weight: 600;

	&.ag-row-hover * {
		background-color: white;
	}

	* {
		cursor: default;
	}
}
.ag-cell ion-input {
	background-color: white;
}
.ag-cell .icon-item-uploaded {
	margin-left: 12px;
	margin-bottom: -2px;
	color: darkgreen;
	font-size: 1.4em;
}
div.ag-row .ag-row-group {
	background-color: var(--ion-color-chat-grey);
}

ion-card {
	border-radius: #{$border-radius-medium};
}

// Ion Modals
ion-modal {
	--border-radius: #{$border-radius-medium};
}
ion-modal ion-content {
	--background: white;
}
ion-modal.fullscreen {
	--width: 100%;
	--height: 100%;
}
.ion-modal-buttons {
	margin-right: 10px;

	svg {
		font-size: 1.4em;
	}
}
ion-content.tight-margins-modal {
	--padding-top: 0px;
	--padding-bottom: 0px;
	--padding-start: 24px;
	--padding-end: 24px;
}
ion-modal.modal-default.show-modal:last-of-type {
	/* Fixes the modal background when opening a modal on top of an existing modal */
	--box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
	--backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

// Full screen-height card class
.full-height-card {
	height: calc(100vh - 134px);
	overflow: hidden;
}

// Context menu
.contextMenuWrapper {
	background-color: white;
	border: 1px solid #eee;
	border-radius: #{$border-radius-small};
	box-shadow: 0 8px 9px -4px rgba(50, 50, 50, 0.4);
}
.contextMenuListItem {
	background-color: white;
	font-size: 0.9em;
	padding: 6px 22px;
	border-radius: #{$border-radius-small};
	cursor: pointer;

	&:hover {
		color: #333;
		background-color: #{$theme-light};
	}
}

// Toastify
.Toastify__toast-container {
	--toastify-z-index: 99999;
}
.toast-link {
	color: $theme-std;
	font-weight: 500;
	text-decoration: underline;
}
.toast-link:hover {
	color: $theme-mid;
}

// WYSIWYG Draft Editor
.wysiwyg-editor-class,
.wysiwyg-editor-class-readonly {
	padding: 6px 8px;
	border: 1px solid #ccc;
	div {
		margin: 0;
		padding: 0;
	}
}
.wysiwyg-editor-class-readonly {
	border: 1px solid var(--ion-color-medium);
	border-radius: #{$border-radius-medium};
	background-color: rgba(255, 255, 204, 0.2);
}
.wysiwyg-toolbar-class {
	border: 1px solid #ccc;
}
.DraftEditor-root {
	height: 100%;
}

// Module submenu items
.module-submenu-item.full-height-card:not(.submenu-workertabs) {
	height: calc(100vh - 134px);
	overflow: hidden;
}

// Photo thumbnails
.photo {
	border: 1px solid var(--ion-color-medium);
	border-radius: #{$border-radius-medium};
	box-shadow: $z-2;
	text-align: center;
	padding: $padding-small;
	padding-bottom: 0;

	.photo-image-container {
		height: 240px;
		overflow: hidden;
	}

	.gallery-photo {
		width: 100%;
		border-radius: #{$border-radius-small};
		cursor: pointer;
		height: 240px;
		object-fit: cover;

		&.gp-no-click {
			cursor: default;
		}
	}

	.gp-show {
		display: inline-block;
	}

	.gp-hide {
		display: none;
	}

	.photo-details {
		padding: $padding-std;
		margin: 0;

		li {
			display: inline-block;
			margin-right: 5px;

			&:last-child {
				margin-right: 0px;
			}
		}
	}
}

// Photo modal
.photo-modal {
	ion-content {
		--background: black !important;
	}

	.modal-photo {
		position: absolute;
		object-fit: contain;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

// Text Message Modal
.text-message-modal {
	.char-count {
		font-size: 0.9em;
		text-align: right;
		margin-top: $margin-small;
	}
}

// Paginator
.paginator {
	width: 30%;

	.pageInfo {
		text-align: center;
		font-size: 1em;
		align-self: center;
	}
}

// Link buttons
.link-button {
	display: inline-block;
	color: $theme-std !important;
	user-select: none;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}

	&.disabled {
		color: var(--ion-color-light-grey);
		cursor: not-allowed;

		&:hover {
			text-decoration: none;
		}
	}
}

// Pop-overs
ion-content.popover-content {
	--padding-top: 0px;
	--padding-bottom: 0px;
	--padding-start: 10px;
	--padding-end: 10px;
}

// Ion form controls to look like Propeller controls
.ion-propeller {
	border: 1px solid var(--ion-color-medium);
	border-radius: #{$border-radius-medium};

	.native-input {
		padding: 6px;
	}
}

.date-expand {
	.mbsc-calendar-controls .custom-nav {
		display: flex;
		justify-content: center;
		flex: 1 0 auto;
	}
	.mbsc-datepicker-inline {
		height: inherit;
	}
	.date-expand-control {
		width: 100%;
		display: flex;
		justify-content: center;
		padding-bottom: 8px;
		.knob {
			cursor: pointer;
			width: 75px;
			height: 10px;
			border-radius: $border-radius-medium;
			background-color: var(--ion-color-dark);
		}
	}
}

.flex-end {
	justify-content: flex-end;
}

.flex-grow-container {
	display: flex;
	flex-direction: column;
}

.align-center {
	text-align: center;
}

.half-day-col .react-select-container {
	width: 110px;
}

// Propeller tooltip (used in Mobiscroll and AG Grid)
.prop-tooltip .mbsc-popup-content {
	padding: 0;
}

.prop-tooltip {
	font-size: 15px;
	font-weight: 600;
}

.prop-tooltip-header {
	padding: 12px 16px;
	color: #eee;
	background-color: $theme-mid;
}

.prop-tooltip-info {
	padding: 8px 16px;
	position: relative;
	line-height: 32px;
}

.prop-tooltip-text {
	font-weight: 300;

	ul {
		margin: -4px 0 2px 0;
		line-height: 1.4em;

		li {
			list-style-type: disc;
		}
	}

	ul.avatars {
		display: flex;
		flex-direction: column;
		gap: 6px;
		margin: 0 0 4px -30px;
	}
}

/* Filter popover */
.filter-popover {
	&::part(content) {
		min-width: 420px;
	}

	&.filter-popover-start-right::part(content) {
		margin-left: 10px;
	}

	ion-content {
		--padding-top: 10px;
		--padding-bottom: 15px;
		--padding-start: 20px;
		--padding-end: 20px;
	}

	ion-toolbar {
		--min-height: auto !important;
	}

	ion-title {
		font-size: 1em;
		padding-top: 0.45em;
		padding-bottom: 0.45em;
	}

	.prop-form ion-col,
	ion-button {
		margin-right: 0;
		padding-right: 0;
	}

	ion-label {
		font-size: 0.94em;
	}
}

.btn-filter-popover {
	ion-badge {
		position: absolute;
		font-size: 8pt;
		right: -18px;
		top: -5px;
	}

	&::part(native) {
		overflow: visible;
	}
}

// Mask to prevent use beneath
.prop-mask {
	position: absolute;
	z-index: 9000;
	width: 100%;
	height: 100%;
	background-color: #e5e5e5;
	opacity: 0.5;
}

// Button styles
.btn-large-icon {
	width: auto !important;
	height: auto !important;

	svg {
		font-size: 7em;
		margin: 30px;
		margin-bottom: 0;
	}

	.btn-large-icon-label {
		margin: 20px 0;
	}
}

// Role badge
span.role-badge {
	ion-badge {
		margin-right: 6px;
	}
}

div.role-badge {
	position: absolute;
	left: 5px;
	top: 5px;
}

// Reports
.report-header-back {
	display: flex;
	flex-direction: row;
	gap: 16px;

	.title-backbutton {
		margin: 0;
		margin-top: -6px;
	}

	.report-header-title {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: start;
		flex: 1 1 100%;
		font-size: 1.1rem;
		font-weight: bold;
	}
}

// Ratings
.rating-stars-container {
	display: flex;
	align-items: center;
	justify-content: center;

	.rating-star {
		color: #faaf00;

		&.no-sel {
			color: #bdbdbd;
		}
	}
}

// Empty page
.no-data-placeholder {
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;

	svg {
		font-size: 8em;
		margin-top: $margin-std;
		color: #f3f3f3;
	}

	.no-data-placeholder-title {
		text-align: center;
		font-size: 1.2em;
		padding: 0 $padding-large $padding-small $padding-large;
		margin: 0 $margin-large;
		margin-bottom: $margin-small;
		border-bottom: 1px solid $theme-light-grey;
	}
}

.obfuscated-text-entry {
	text-security: disc;
	-webkit-text-security: disc;
	-moz-text-security: disc;
}
