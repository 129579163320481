.component-utilities-item_catalogue {
	label {
		display: block;
		margin-top: 9px;
		margin-right: 3px;
	}
}

.item-modal {
	ion-row:not(.title-row) {
		margin-bottom: 10px;
	}

	ion-row.title-row ion-col {
		padding-bottom: 0;
	}

	ion-footer ion-row {
		margin-bottom: 0 !important;
	}

	.prop-form ion-col {
		padding: 0;
	}

	/* The number fields */
	ion-row.prop-form {
		margin: 0;
	}
}
