@import '../../assets/scss/theme-defaults.scss';
@import './widgets/Widgets.scss';

/* Grid styles */
.react-grid-item.react-grid-placeholder {
	background-color: var(--ion-color-primary);
}

.dashboard-layout-container {
	width: 100%;

	/* React select dropdowns */
	.react-select-container {
		.react-select__control {
			height: 32px;
			min-height: 32px;
			border-color: #9ea0aa;
			border-radius: var(--ion-border-radius-medium);
		}
		.react-select__value-container {
			margin-top: -1px;
			margin-left: -4px;
		}
		.react-select__indicators {
			margin-top: -1px;
		}
	}

	.widget {
		.prop-loading {
			height: auto !important;
			top: 50px !important;
			bottom: 55px;
		}
	}
}

/* Modals */
.usage-mode-dashboard.hatrequest-request-modal,
.usage-mode-dashboard.hatsick-sick-modal {
	--width: 640px !important;
	--height: 380px !important;
}
