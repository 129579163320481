.thumbnail {
	position: relative;
	width: 100%;
	height: 240px;
	overflow: hidden;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: var(--ion-border-radius-small);
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	.play-icon {
		color: var(--ion-color-white);
		font-size: 2rem;
		border: 2px solid var(--ion-color-white);
		border-radius: 50%;
		width: 50px;
		height: 50px;
		text-align: center;
	}
}