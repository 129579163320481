.conversation-item {
	--ion-item-background: var(--ion-color-white);
	margin-bottom: 5px;
	--ion-item-border-color: var(--ion-color-white);

	.icon-surround {
		border-radius: 50%;
		background-color: var(--ion-color-light-grey);
		color: var(--ion-color-dark);
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.end-surround {
		flex: 1;
		flex-direction: 'row';
		
		span {
			font-size: 0.8rem;
		}
	}
}