@import './../../../assets/scss/theme-defaults.scss';

.tbl-estimate {
	ion-col {
		padding: 0;
	}

	.MuiTableBody-root .MuiTableRow-root:nth-child(1) .MuiTableCell-root {
		padding-top: 8px;
	}

	.cell-client-details {
		vertical-align: top;
	}

	ion-label {
		display: inline-block;
		padding-bottom: 4px;
	}

	#client-details {
		height: 177px;
		padding: 4px 1px;
	}

	.row-title-summary.hidden {
		display: none;
	}

	.btn-show-title-summary {
		padding-bottom: 6px;
	}

	.react-select-container {
		.react-select__control {
			height: 33px !important;
			min-height: 33px !important;
			border-color: #9ea0aa;
			border-radius: var(--ion-border-radius-medium);
			margin-top: -2px;
		}
		.react-select__value-container {
			margin-left: -4px;
		}
	}
}

.tbl-estimate-items {
	ion-col {
		padding: 0;
	}

	.form-info-box-output {
		height: 32px !important;
		overflow: hidden;
		text-align: right;
	}

	.cell-info {
		padding-top: 8px;
	}

	.cell-button {
		padding-top: 4px;

		ion-button {
			height: 34px;
			margin: 0;
		}
	}

	.MuiTableCell-head,
	.MuiTableCell-body {
		padding-left: 5px;
		padding-right: 5px;
		vertical-align: bottom;
	}

	.MuiTableCell-head:first-child,
	.MuiTableCell-body:first-child {
		padding-left: 16px;
	}
	.MuiTableCell-head:last-child,
	.MuiTableCell-body:last-child {
		padding-right: 16px;
	}

	.MuiTableCell-footer {
		font-size: 1em;
	}

	.ion-number-input {
		text-align: right;
	}
}

.estimate-footer {
	margin-left: 6px;
	margin-right: 6px;

	.lbl-terms {
		display: inline-block;
		font-weight: 500;
		font-size: 1em;
		color: rgba(0, 0, 0, 0.87);
		padding-bottom: 5px;
	}

	.terms-toolbar {
		display: none;
	}

	.terms-editor {
		padding: 4px 6px;
		height: 180px;
		overflow-y: scroll;
	}

	.tbl-totals {
		margin-top: 20px;

		.MuiTableCell-head,
		.MuiTableCell-body {
			text-align: right;
		}
	}
}

// Other
.popover-mark-estimate {
	--offset-x: 165px;
	--offset-y: -127px;
	--width: 264px;

	&.items-count-3 {
		--offset-y: -175px;
	}
}

.popover-content svg {
	margin-right: 7px;
}

#btn-mark-estimate svg {
	margin-left: 6px;
}
.btn-mark-approved svg {
	color: var(--ion-color-success);
}
.btn-mark-issued svg {
	color: var(--ion-color-alt);
	rotate: -90deg;
}
.btn-mark-refused svg {
	color: var(--ion-color-danger);
}
.btn-mark-draft svg {
	color: var(--ion-color-medium);
}
