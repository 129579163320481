.component-utilities-account_details-license_agreement {
	ion-content {
		height: calc(100vh - 288px);
	}

	.agreement-content {
		p {
			padding: 0;
			margin: 0;
		}
	}

	.btn-print {
		svg {
			padding-right: 4px;
		}
	}
}

.message-modal {
	--width: 70%;
}

.component-utilities-account_details-messages {
	.full-height-card {
		height: calc(100vh - 174px);
	}
}
