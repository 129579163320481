@import "./../../../assets/scss/theme-defaults.scss"; // Use defaults defined there

.reaction-picker {
	--width: 300px;
	--height: 30px;
	
	.ion-delegate-host {
		flex-direction: row !important;
	}

	.emojiWrapper {
		flex: 1;
		justify-self: center;
		align-self: center;
		line-height: 100%;
		text-align: center;
		cursor: pointer;
	}
}