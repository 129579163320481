@import '../../External.scss';

.external-my-page {
	.sub-section-divider {
		width: 100%;
		height: 1px;
		margin: $margin-std 0;
		padding: 0;
		background-color: $lightGrey;

		&.ssd-invisible {
			background-color: transparent;
		}
	}

	.address-item {
		font-size: var(--font-size-std);

		svg {
			padding-right: 3px;
		}
	}

	.text-item {
		font-size: var(--font-size-std);
		margin-bottom: $margin-std;
	}

	.estimate-header {
		display: flex;
		flex-direction: row;
		gap: var(--gap-large);

		.company-details {
			display: flex;
			flex-direction: row;
			flex: 1 1 100%;
			gap: var(--gap-std);

			.company-address {
				flex: 1 1 100%;
			}

			.basic-details {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: end;
				text-align: right;
				flex: 0 0 200px;

				.basic-details-item div {
					font-size: var(--font-size-std);
				}
			}
		}
	}

	.your-job-details {
		display: flex;
		flex-direction: column;
		gap: var(--gap-std);

		.job-details-row {
			display: flex;
			flex-direction: row;
			font-size: var(--font-size-std);

			.left {
				flex: 0 0 210px;
				font-weight: bold;
			}

			.right {
				flex: 1 1 100%;
			}
		}
	}

	.button-bar {
		display: flex;
		justify-content: end;
		margin: $margin-large 0 0 0;
		padding: $padding-xsmall;
		background-color: $theme-background-light;
		border-radius: $border-radius-small;

		ion-button {
			margin: 0 0 0 $margin-small;
		}
	}

	.items-table {
		th,
		td {
			padding: 8px;
			text-align: right;
			font-size: var(--font-size-std);
		}

		th:first-child,
		td:first-child {
			text-align: left;
		}

		thead {
			th {
				font-weight: bold;
				text-transform: uppercase;
				border-top: 1px solid #aaa;
				border-bottom: 1px solid #aaa;
			}
		}

		.total-row {
			td {
				border-bottom: none;
			}

			td:first-child {
				text-align: right;
				font-weight: bold;
				text-transform: uppercase;
			}
		}
	}

	.company-logo {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 0 0 $client-logo-width;
		height: $client-logo-height;
		overflow: hidden;
		background-color: white;
		background-size: cover;
	}

	/* Photo gallery */
	.photo {
		.prop-loading {
			width: 160px;
			height: 160px;
		}
		.photo-image-container {
			height: 160px;
		}
		.photo-details {
			font-size: var(--font-size-std);
			padding: 12px 16px;
		}
		.gallery-photo {
			height: auto;
		}
	}
}
