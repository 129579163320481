@import './../../../assets/scss/theme-defaults.scss';

.component-jobs-jobs_list {
	.filter-by {
		padding: 2px 0 0 0;
	}
	.lbl-filter-by {
		margin-right: 10px;
		line-height: 2.7em;
	}
}

.component-jobs-jobs_list-job_card {
	.prop-form .info-box-border {
		height: 37px;
	}

	.form-info-box-output {
		margin-top: 2px;
	}

	.form-info-box-col {
		padding-bottom: 0;
	}
}

.component-jobs-reports {
	.react-select__control {
		height: auto !important;
		background-color: white;
	}
	.react-select__value-container {
		padding-top: 2px !important;
	}

	.extra-footer-controls-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: $margin-std;
		margin-top: $margin-small;

		.efc-label {
			flex: 0 0;
			flex-basis: 140px;
			text-align: right;

			&:first-child {
				flex-basis: 100px;
			}
		}

		.efc-control {
			flex: 1 1 100%;
		}
	}
}

/* Jobs: Submenu items */
.module-submenu-item.submenu-jobmessages {
	height: 100%;

	.content {
		padding: $padding-xsmall;
	}
}

.module-submenu-item.submenu-jobnotes {
	h5 {
		padding: 0;
		margin: 0;
	}
}

.module-submenu-item.submenu-jobdiary {
	// Match grid container spacing
	padding: 31px 29px;

	.jobdiary-container {
		display: flex;
		flex-direction: column;
		align-items: start;
		height: 100%;

		& > div {
			width: 100%;
		}

		.for-print {
			display: none;
		}

		.split-view {
			display: flex;
			flex-direction: row;
			align-items: start;
			flex: 1 1 100%;
			height: 0;
			gap: 10px;

			.split-view-left {
				display: flex;
				flex-direction: column;
				justify-content: start;
				flex: 0 0 280px;
				overflow-y: auto;
				height: 100%;
				gap: 20px;

				.calendar-container {
					.mbsc-datepicker {
						.mbsc-calendar-controls {
							justify-content: space-between;
						}
					}
				}

				.filters {
					flex: 1 1 100%;

					h4 {
						margin-bottom: $margin-large;
					}

					.timeline-filter-row {
						display: flex;
						flex-direction: row;
						align-items: center;
						padding-left: $padding-std;
						margin-right: $margin-large;
						gap: 10px;
						color: black;
						cursor: pointer;

						&.inactive {
							color: $grey;
							font-weight: normal;
						}

						&:hover {
							color: $theme-std;
							background-color: #fafafa;
						}

						.tfr-dot {
							flex: 0 0;

							.MuiTimelineDot-root {
								margin: 8px 0;
							}
						}

						.tfr-label {
							flex: 1 1 100%;
							font-size: 1.1em;
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							align-items: center;
							user-select: none;

							svg {
								color: #222222;
							}
						}
					}
				}
			}

			.job-diary-timeline-container {
				flex: 1 1 100%;
				height: 100%;

				/* Virtual scroller */
				.MuiTimeline-root {
					width: 100%;
					height: 100%;
					margin: 0;
					padding: 0;
				}

				/* Force alternating rows */
				.MuiTimelineItem-positionAlternate.push-right {
					flex-direction: row !important;

					& > * {
						text-align: left !important;
					}
				}
				.MuiTimelineItem-positionAlternate.push-left {
					flex-direction: row-reverse !important;

					& > * {
						text-align: right !important;
					}
				}

				ion-col {
					padding: 0;
				}

				.timeline-date {
					text-align: center;
					font-size: 1.2em;
					padding-bottom: $padding-small;
					margin: 0 $margin-large;
					margin-bottom: $margin-small;
					border-bottom: 1px solid $theme-light-grey;
				}

				div:has(.timeline-date):not(:first-child) {
					margin-top: 24px;
				}

				.timeline-seconds {
					font-size: 0.7em;
				}
			}
		}

		.footer {
			display: flex;
			flex-direction: row;
			justify-content: end;
			margin-top: $margin-std;
		}
	}
}

.module-submenu-item.submenu-jobkpi {
	// Match grid container spacing
	padding: 31px 29px;

	.jobkpi-container {
		display: flex;
		flex-direction: column;
		align-items: start;
		height: 100%;

		& > div {
			width: 100%;
		}

		.rating-stars-container {
			margin: $margin-large 0;
			flex-basis: 60%;
			gap: 10px;

			.rating-star {
				font-size: 6em;
			}
		}

		.button-container {
			display: flex;
			align-items: end;
			justify-content: end;
			flex-basis: 100%;
		}
	}
}

.module-submenu-item.submenu-jobworkertimesheets {
	&.propeller-content-card {
		padding: 0 !important;
	}

	ion-card {
		padding: 20px 24px;
		box-shadow: none;
		border-radius: 0;
	}

	.btn-controls {
		justify-content: right;
	}

	.row-lower-controls-left {
		.worker-filter {
			width: 400px;
			margin: 4px 6px 0 0;
		}

		.row-lower-controls-container {
			display: flex;
			justify-content: left;
		}
	}

	.row-lower-controls-right {
		.row-lower-controls-container {
			display: flex;
			justify-content: right;
		}
	}

	.cumulative-cost-container {
		height: 100%;

		.data-grid-container {
			.header-row {
				&.hr-main {
					background-color: $theme-alt;
				}
			}
		}
	}
}

@media print {
	.job-diary-timeline-container {
		// Virtuoso scroller
		div[data-test-id] {
			overflow-y: visible !important;
		}

		div[data-viewport-type] {
			position: relative !important;
		}

		// Always show the gallery photo
		.gp-hide {
			display: inline-block !important;
		}

		.for-print {
			display: inherit;
		}

		.timeline-date:not(:first-child) {
			margin-top: 30px;
		}

		.timeline-seconds {
			font-size: 0.7em;
		}

		.print-header {
			margin-bottom: 20px;

			h1 {
				display: block;
				margin: 0;
				padding: 12px 6px;
				color: white;
				background-color: $theme-beta-primary-dark;
			}

			h3 {
				display: block;
				margin: 0;
				padding: 8px 6px;
				background-color: $chatGrey;
			}
		}

		.timeline-structure {
			page-break-inside: avoid;
		}

		.timeline-date {
			text-align: center;
			font-size: 1.1em;
			padding-bottom: $padding-small;
			margin: 0 $margin-large;
			margin-bottom: $margin-small;
			border-bottom: 1px solid $theme-light-grey;
		}

		.dotted {
			display: inline !important;
			border: none !important;
		}
	}
}
