@keyframes highlight-effect-animation {
	from {
		background: #ffffaa;
	}
	100% {
		background: transparent;
	}
}

.highlight-effect {
	animation-name: highlight-effect-animation;
	animation-duration: 0.8s;
	animation-iteration-count: 1;
}
