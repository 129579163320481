.item-list {
	margin-top: 6px;

	ul {
		padding: 0;
		display: flex;
		flex-direction: column;
	}

	li {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-grow: 1;
		margin: 3px 0;
		height: 28px;
		overflow: hidden;
		border-radius: var(--ion-border-radius-medium);
		border: 1px solid #babcc7;
	}

	ul > :first-child {
		margin-top: 0;
	}

	ul > :last-child {
		margin-bottom: 0;
	}

	li * {
		flex-basis: 0%;
		margin: 0;
	}

	span {
		padding-left: 4px;
	}

	li:hover .icon-device,
	.item-btn:hover + .icon-device {
		color: var(--ion-color-dark);
	}

	li:hover,
	.item-btn:hover + li {
		background-color: var(--ion-color-tertiary);
	}

	label {
		flex-grow: 3;
		padding: 6px;
	}

	.icon-device,
	.icon-device-no-edit {
		color: #888;
	}
}

// Default size for react-select option lists to match closed state
[class$='-MenuPortal'] {
	font-size: 0.9rem;
}

// Yes No Value
.yes-no-value-container {
	display: flex;
	flex-direction: row;

	.yes-no-value-dropdown-container {
		flex: 0 0 100px;
	}

	.yes-no-value-input-denominator {
		flex: 0 0 60px;
		text-align: right;
		line-height: 2.3em;
		margin-right: 4px;
	}

	.yes-no-value-input-container {
		flex: 0 0 120px;
	}

	.yes-no-value-end-fill {
		flex: 1 1 100%;
	}
}
