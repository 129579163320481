@import './../../../../assets/scss/theme-defaults.scss';

.component-utilities-working_hours_and_breaktimes {
	.cal-title {
		margin-bottom: 0;
		line-height: calc(1rem + 12px);
	}

	.prop-form-edit ion-col {
		padding: 0;
	}

	.breaktime-select,
	.locations-select {
		width: 90%;
		margin: 0 auto;
		font-size: 13px;

		.react-select__value-container {
			margin-top: -1px !important;
		}
		.react-select__indicators {
			margin-top: -2px;
		}
	}

	/* Make the dropdown arrow less wide */
	[class$='-indicatorContainer'] {
		padding: 1px !important;
	}

	/* Widen the dropdown label/value display */
	[class$='-ValueContainer'] {
		padding: 2px 2px !important;
	}

	.working-hours-calendar,
	.mbsc-schedule-grid-scroll {
		max-height: calc(100vh - 337px) !important;
	}

	.prop-breaktime,
	.prop-locations {
		background-color: #fafafa !important;
		border-top: 1px solid #e8e8e8 !important;
	}

	.label-breaktimes {
		position: absolute;
		bottom: 88px;
		left: 24px;
		font-size: 0.8em;
	}

	.label-locations {
		position: absolute;
		bottom: 39px;
		left: 32px;
		font-size: 0.8em;
	}

	.undo-icon {
		margin: 0;
		top: 12px;
		left: 12px;
	}

	.btn-undo {
		position: absolute;
		right: 18px;
		top: 18px;

		svg {
			padding-right: 4px;
		}
	}

	.prop-col-resize {
		width: 68px;
	}
}
