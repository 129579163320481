@import "./../../../../assets/scss/theme-defaults.scss";

.icon-add {
  font-size: 1.2rem;
}

.icon-delete {
  font-size: 1.2rem;
}

.new-rate {
  background-color: #e2e5e8;
}
