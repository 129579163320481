@import "./../../../assets/scss/theme-defaults.scss"; // Use defaults defined there

.content {
	flex: 1;
	overflow: hidden;
	background-color: var(--background);
}

ion-modal#notification-details-modal {
	--backdrop-opacity: 0.5;
}