@import '../../assets/scss/theme-defaults.scss';

.component-workers-workers_list-add_new {
	input[name='Latitude'],
	input[name='Longitude'] {
		&.native-input[disabled].sc-ion-input-md:not(.cloned-input) {
			opacity: 1;
		}
	}

	.staff-image {
		/* 3:4 ratio */
		width: 208px;
		height: 277px;
		min-width: 208px;
		min-height: 277px;
		display: block;
		margin: 0 auto;
		background-color: white;
	}
}

.component-workers-workers_list-worker_card {
	.staff-image {
		/* 3:4 ratio */
		width: 186px;
		height: 248px;
		min-width: 186px;
		min-height: 248px;
		display: block;
		margin: 0 auto;
		background-color: white;
	}

	.component-utilities-working_hours_and_breaktimes {
		h5 {
			margin-top: 10px !important;
			margin-bottom: 20px;
		}
		.btn-undo {
			top: -2px;
			right: 3px;
			z-index: 100;
		}
		.label-breaktimes {
			bottom: 64px;
			left: 8px;
		}
		.label-locations {
			bottom: 15px;
			left: 16px;
		}
		.working-hours-calendar,
		.mbsc-schedule-grid-scroll {
			max-height: calc(100vh - 432px) !important;
		}
	}

	.component-utilities-management_structure {
		&.full-height-card {
			height: calc(100vh - 274px) !important;
			overflow: hidden;
		}

		.org-tree-container {
			height: calc(100vh - 362px);
		}
	}

	.work-calendar {
		.ev-lbl {
			svg {
				margin-right: 4px;
			}
		}
	}

	.table-card {
		box-shadow: none;
		padding: 0;
	}

	.full-height-card {
		height: calc(100vh - 290px);
		overflow: hidden;
	}
}

.component-workers-workers_list-import {
	ul {
		margin: $margin-std 0;
	}
	ul li {
		list-style-type: disc;
	}
}

.component-workers-reports {
	.react-select__control {
		height: auto !important;
		background-color: white;
	}
	.react-select__value-container {
		padding-top: 2px !important;
	}

	.extra-footer-controls-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: $margin-std;
		margin-top: $margin-small;

		.efc-label {
			flex: 0 0;
			flex-basis: 180px;
			text-align: right;

			&:first-child {
				flex-basis: 120px;
			}
		}

		.efc-control {
			flex: 1 1 100%;
		}
	}
}

.component-workers-workers_list-worker_card-assessment {
	padding: 11px 5px;
	display: flex;
	flex-direction: column;
	height: 100%;

	.table-container {
		flex: 1;
		overflow-y: auto;

		table {
			border-spacing: 0;
			border-collapse: separate;
			border-radius: $border-radius-small;
			border: 1px solid $theme-light-grey;
			border-bottom: none;
			overflow: hidden;

			td {
				text-align: right;
				padding: 14px;
				border-right: 1px solid $theme-light-grey;
			}
		}

		td:last-child {
			border-right: none;
		}

		tr:nth-child(odd) {
			td:first-child {
				font-weight: bold;
				text-align: left;
				background: #f1f1f1;
			}

			td:not(:first-child) {
				background-color: #fafafa;
			}
		}
	}

	.filter-buttons-container {
		flex: 0;
		margin-top: $margin-std;
	}
}

/* Workers: Submenu items */
.module-submenu-item.submenu-workermessages {
	height: 100%;

	.content {
		padding: $padding-xsmall;
	}
}

.module-submenu-item.submenu-workernotes {
	h5 {
		padding: 0;
		margin: 0;
	}
}

.module-submenu-item.submenu-workerusage {
	.full-height-card {
		height: calc(100vh - 180px);
	}
}

.module-submenu-item.submenu-workermap {
	.locate-on-map {
		height: calc(100vh - 320px) !important;
	}
}

.module-submenu-item.submenu-workertraining {
	.ag-row-pinned [col-id='start'] {
		text-align: right;
	}
}

.module-submenu-item.submenu-workerdiary {
	.mbsc-calendar-label {
		cursor: help;
	}

	.bg-cal-cell {
		background-position: 97% 13px !important;
	}
}

/* Workers: Holiday Chart */
.submenu-hatholidaychart {
	.prop-timeline {
		margin-top: -30px;
		height: calc(100vh - 226px) !important;

		.mbsc-timeline-event {
			cursor: help;
		}

		// Disable hover effect on events
		.mbsc-schedule-event-background-static {
			border-radius: 0.333334em;
			background: currentColor;
			left: 1px;
			position: absolute;
			right: 1px;
		}
	}

	// Expand the resources column
	.mbsc-timeline-resource-col {
		width: 16em;
	}

	.resource-avatar-container {
		margin: 2px 0 0 0;
	}
}

/* Workers: Requests */
/* Workers: Sick */
.module-submenu-item.submenu-hatrequests,
.module-submenu-item.submenu-hatsick {
	.row-lower-controls-left {
		.worker-filter,
		.approver-filter {
			width: 400px;
			margin: 4px 6px 0 0;
		}

		.row-lower-controls-container {
			display: flex;
			justify-content: left;
		}
	}

	.row-lower-controls-right {
		.row-lower-controls-container {
			display: flex;
			justify-content: right;
		}
	}
}
.module-submenu-item.submenu-hatsick {
	.filter-data-table {
		.prop-data-table {
			height: calc(100vh - 340px) !important;
		}
	}
}

// Modals
.worker-diary-day-schedule-modal {
	.mbsc-calendar {
		margin-top: -130px;
	}
	.mbsc-schedule-grid-scroll {
		height: auto;
		max-height: inherit !important;
	}
	.mbsc-calendar-header,
	.mbsc-schedule-header {
		visibility: hidden;
	}
	.mbsc-schedule-event-hover {
		cursor: default;
	}
}

.hatrequest-request-modal {
	.info-box-multi-line:not(.info-box-request-clash) {
		height: 73px;
	}

	.info-box-multi-line {
		.form-info-box-output {
			padding-top: 4px;
			padding-bottom: 6px;
		}
	}

	.info-box-request-clash {
		height: auto;
		overflow-y: visible !important;
	}
}

.hatsick-sick-modal {
	.managers-notes textarea {
		height: 14em;
	}
}

.hattrainingevent-training-event-modal {
	.prop-form .react-select-container .react-select__value-container {
		margin-top: -3px;
	}
}
