@import "./../../../assets/scss/theme-defaults.scss"; // Use defaults defined there
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.media-close {
	position: absolute;
	top: 10px;
	right: 0px;
	z-index: 1000 !important;
	filter: drop-shadow(0 2px 2px #1a1a1a);
	font-size: 1.6rem !important;
	--padding: 20px;
}

.media-link {
	width: 100%;
	height: calc(80vh - 64px);
	overflow: hidden;
	display: flex !important;
	align-items: center;
	justify-content: center;
}

.chat-media {
	--width: 80vw;
	--height: 80vh;

	ion-content {
		--padding-bottom: 0;
		--padding-top: 0;
		--padding-start: 0;
		--padding-end: 0;
		text-align: center;
		vertical-align: middle;

		ion-spinner {
			margin-top: calc(40vh - (28px / 2));
		}
	}
}

.image-gallery-content .image-gallery-slide {
	height: calc(80vh - 64px);
	overflow: hidden;
	display: flex !important;
	align-items: center;
	justify-content: center;
}

.image-gallery-content .image-gallery-slide .image-gallery-image{
	max-height: calc(80vh - 80px);
}

.image-gallery-slide .image-gallery-image {
	width: auto;
}