@import "./../../assets/scss/theme-defaults.scss"; // Use defaults defined there

:root {
	--toastify-color-error: var(--ion-color-danger);
}

.Toastify__toast-container--bottom-center {
	width: 375px;
}

.worker-in-danger {
	padding: #{$padding-small};
	
	h2 {
		margin: 0;
	}

	.Toastify__toast-icon {
		margin-inline-end: #{$margin-large};
	}

	ion-toolbar {
		--min-height: 40px;
	}
}