@import './../../assets/scss/theme-defaults.scss'; // Use defaults defined there.

.gm-style {
	.react-select-container {
		padding: 5px 8px;
		margin-top: 4px;
		background-color: var(--ion-color-white);
		box-shadow: $google-large-box-shadow;
		width: 30vw;

		.react-select__control {
			border: none;
		}

		.react-select__control--is-focused {
			border: none;
			box-shadow: none;
		}
	}

	.react-select-container:has(.react-select__control) {
		border-radius: #{$border-radius-large};
	}

	.react-select-container:has(.react-select__control--menu-is-open) {
		border-radius: #{$border-radius-large} #{$border-radius-large} 0 0;
	}
}

.react-select-menu {
	top: -5px !important;
	left: -6px !important;
	width: 30vw !important;
	border-radius: 0 0 #{$border-radius-large} #{$border-radius-large} !important;
	border: none !important;
	box-shadow: $google-large-box-shadow !important;
}

.react-select-menu-list {
	border: none !important;
}

.react-select-option {
	padding: 7px 15px !important;
}