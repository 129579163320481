@import './theme-defaults.scss'; // Use defaults defined there
@import './functions.scss';

*,
*:after,
*:before {
	--ion-font-family: #{$theme-font};
	--ion-text-color: #{$theme-font-color};
	--ion-background-color: #{$theme-background-light};
	--ion-border-radius-small: #{$border-radius-small};
	--ion-border-radius-medium: #{$border-radius-medium};
	--ion-border-radius-large: #{$border-radius-large};

	// Colours changed to be more muted/complementary
	// & green changed to match Propeller green
	// Background colours vs font colours checked with
	// https://oomphinc.github.io/colorcube/
	@each $color-name, $color-base in $theme {
		$text-color: text-contrast($color-base);
		$rgba-color: rgba($color-base, 1);
		$rgba-contrast: rgba($text-color, 1);
		$shade: scale-color($color-base, $lightness: -10%);
		$tint: scale-color($color-base, $lightness: +10%);

		--ion-color-#{$color-name}: #{$color-base};
		--ion-color-#{$color-name}-rgb: #{$rgba-color};
		--ion-color-#{$color-name}-contrast: #{$text-color};
		--ion-color-#{$color-name}-contrast-rgb: #{$rgba-contrast};
		--ion-color-#{$color-name}-shade: #{$shade};
		--ion-color-#{$color-name}-tint: #{$tint};
	}
}

// Icon sizes
@each $size-name, $size-base in $icon-sizes {
	.icon-#{$size-name} {
		font-size: $size-base;
		cursor: pointer;
	}
}

/** Ionic CSS Variables **/
:root {
	*:not(.ag-icon, .ag-radio-button-input-wrapper) {
		font-family: #{$theme-font};
	}

	.inner-scroll {
		color: #{$theme-font-color}; // Text shows up white on some Android phones if not set
	}

	// Sets colours for the toasts, cards & icons

	@each $color-name, $color-base in $theme {
		ion-toast.#{$color-name}::part(container) {
			border: 3px solid var(--ion-color-#{$color-name});
		}

		.card-#{$color-name} {
			border: 1px solid var(--ion-color-#{$color-name});
			outline: 0;
			box-shadow: 0 0 0 0.1rem #{toRGBa($color-base, '20%')};
		}

		.icon-#{$color-name} {
			color: var(--ion-color-#{$color-name});
		}

		.font-#{$color-name} {
			color: var(--ion-color-#{$color-name});
		}

		.background-#{$color-name} {
			background: var(--ion-color-#{$color-name});
		}

		.badge-#{$color-name} {
			background: var(--ion-color-#{$color-name});
			padding: $padding-xsmall;
			font-size: 0.6rem;
			width: 17px;
			border-radius: 50%;
			height: 17px;
			margin-top: 3px;
			margin-left: 8px;
			line-height: 0.4rem;
		}

		.ion-color-#{$color-name} {
			--ion-color-base: var(--ion-color-#{$color-name}, #3dc2ff);
			--ion-color-base-rgb: var(--ion-color-#{$color-name}-rgb, 61, 194, 255);
			--ion-color-contrast: var(--ion-color-#{$color-name}-contrast, #fff);
			--ion-color-contrast-rgb: var(--ion-color-#{$color-name}-contrast-rgb, 255, 255, 255);
			--ion-color-shade: var(--ion-color-#{$color-name}-shade, #36abe0);
			--ion-color-tint: var(--ion-color-#{$color-name}-tint, #50c8ff);
		}

		.button-#{$color-name} {
			--background-hover: var(--ion-color-#{$color-name}-contrast, #fff);
			--background-focused: var(--ion-color-#{$color-name}-contrast, #fff);
			--background: var(--ion-color-#{$color-name}, #3dc2ff);
			--color: var(--ion-color-#{$color-name}-contrast, #fff);
		}
	}
}
