@import './../../assets/scss/theme-defaults.scss';

$light-bg: #f7f9fc;

ion-app.external-page {
	--font-size-std: 13px;
	--gap-std: 10px;
	--gap-large: 20px;

	.prop-general-error {
		width: 40%;
		min-width: 600px;
		margin: 20vh auto 0 auto;

		.MuiAlert-root {
			box-shadow: 0 4px 9px -4px rgba(50, 50, 50, 0.4) !important;
		}
	}

	.page-wrapper {
		width: 675px;
		margin: 0 auto;
	}

	.header-default {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		background: $theme-background-light;
		padding: 20px 12px;
		font-size: 1.4em;
		color: $theme-std;
		font-weight: bold;
		border-radius: $border-radius-small;

		img {
			display: block;
		}
	}

	.banner {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 8px 12px;
		margin: $margin-std 0 $margin-std 0;
		font-size: 1.4em;
		color: white;
		background-color: $theme-std;
		border-radius: $border-radius-small;
	}

	h1 {
		font-size: 1.1em;
		font-weight: bold;
	}

	h2 {
		font-size: var(--font-size-std);
		font-weight: bold;
		text-transform: uppercase;
		margin: 0;
	}

	/* Header and footer */
	.header-custom {
		width: $header-width;
		height: $header-height;
		border-radius: $border-radius-small;
	}
	.footer-custom {
		width: $footer-width;
		height: $footer-height;
		border-radius: $border-radius-small;
		margin: $margin-std 0;
	}

	/* General: icons */
	.fa-envelope {
		padding-top: 3px;
	}
}

@media print {
	ion-app.external-page {
		.header-default,
		.banner {
			font-size: 1.2em;
		}

		.button-bar {
			display: none;
		}
	}
}
