@import './../../../assets/scss/theme-defaults.scss'; // Use defaults defined there.
@import './../../../assets/scss/variables.scss'; // Use defaults defined there.

.radio-buttons {
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	z-index: 99;

	ion-button {
		--padding-top: 0;
		--padding-bottom: 0;
		--padding-start: 8px;
		--padding-end: 8px;
	}

	ion-button:not(.active) {
		@extend .button-light;
		--color: var(--ion-color-primary) !important;
		--border-radius: 0 !important;
		margin: 0;
	}
	
	.active {
		@extend .button-primary;
		--border-radius: 0 !important;
		margin: 0;
	}

	ion-button:first-child {
		--border-radius: #{$border-radius-medium} 0 0 #{$border-radius-medium} !important;
		margin: 0;
	}

	ion-button:last-child {
		--border-radius: 0 #{$border-radius-medium} #{$border-radius-medium} 0 !important;
		margin: 0;
	}
}

.gm-style {
	.radio-buttons {
		margin-top: 8px;
		margin-left: 8px;

		ion-button {
			--box-shadow: #{$google-box-shadow};
			--padding-start: 17px;
			--padding-end: 17px;
			--padding-top: 5px;
			--padding-bottom: 5px;
			height: 40px !important;
			text-transform: none;
			font-family: Roboto, Arial, sans-serif;
			font-size: 18px;
			letter-spacing: normal;
			font-weight: 400;
			color: rgb(86, 86, 86);
		}

		ion-button:first-child {
			--border-radius: #{$border-radius-large} 0 0 #{$border-radius-large} !important;
			margin: 0;
		}

		ion-button:last-child {
			--border-radius: 0 #{$border-radius-large} #{$border-radius-large} 0 !important;
			margin: 0;
		}

		ion-button:not(.active) {
			@extend .button-white;
			--color: var(--ion-color-dark) !important;
		}
		
		.active {
			@extend .button-white;
			--border-radius: 0 !important;
			margin: 0;
			font-weight: 500;
			color: rgb(0, 0, 0);
		}
	}
}