@import "./../../../assets/scss/theme-defaults.scss"; // Use defaults defined there

.search-message {
	position: relative;
	box-sizing: border-box;
	margin: 0.1rem;
	max-width: 100%;
	background-color: transparent;
	--background: var(--ion-color-light-grey);
	cursor: pointer;

	ion-card,
	ion-card-header {
		padding: 0;
		color: var(--ion-color-dark);
		margin: 0;
		box-shadow: none;
	}
	
	ion-card-header ion-item,
	ion-card-content {
		background: var(--ion-color-light-grey);
		--background: var(--ion-color-light-grey);
		--border-style: none;
		color: var(--ion-color-dark);
	}

	ion-card {
		position: relative;
		--border-style: none;
		--background: var(--ion-color-light-grey);
		background: var(--ion-color-light-grey);
		border-radius: 0;
		border: 1px solid var(--ion-color-light-grey);
		white-space: pre-line;
		grid-area: message;
		font-weight: normal;
		word-break: normal;
		--ion-color-step-600: var(--ion-color-dark);
		color: var(--ion-color-dark);
		--color: var(--ion-color-dark);
	}

	&.not-read {
		& ion-card {
			border: 1px solid rgba(82, 131, 255, 1);
			box-shadow: 0px 0px 18px 4px rgba(82, 131, 255, 1);
		}
	}

	.time-read {
		margin-top: 5px;
		text-align: left;
		vertical-align: middle;
		color: var(--ion-color-primary);

		span {
			margin-right: 10px;
			font-size: 0.9rem;
			line-height: 0.9rem;
		}
	}

	.doc-link {
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: var(--ion-background-color);
		color: var(--ion-color-dark);
		padding: 5px 10px;

		div {
			width: 100%;
			display: flex;
			flex-direction: column;
		}

		div.file-icon {
			width: 40px;
			font-size: 1.2rem;
		}

		.file-size {
			font-size: 0.8rem;
			color: var(--ion-color-medium);
		}
	}

	.styles-module_wrapper__1I_qj {
		z-index: 100;
	}

	mark {
		padding: 0;
	}
	
}