.component-utilities-system_usage {
	.btn-controls {
		justify-content: right;
	}

	.btn-back {
		svg {
			padding-right: 4px;
		}
	}

	.row-lower-controls-left {
		.worker-filter {
			width: 400px;
			margin: 4px 6px 0 0;
		}

		.row-lower-controls-container {
			display: flex;
			justify-content: left;
		}
	}

	.row-lower-controls-right {
		.row-lower-controls-container {
			display: flex;
			justify-content: right;
		}
	}
}
