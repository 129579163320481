@import "./../../../../assets/scss/theme-defaults.scss"; //

.bank-holidays-calendar-container {
  table.calendar {
    border-collapse: collapse;
    margin: 0 auto;
    width: 100%;
  }

  table.calendar thead {
    background-color: #5a5a5a;
    color: white;
    margin-bottom: 3px;
    border-bottom: 2px solid white;
  }

  table.calendar thead th {
    font-weight: normal;
    padding: 10px 3px;
    text-align: center;
  }

  table.calendar thead th.bolder {
    font-weight: bold;
  }

  table.calendar tbody {
    font-size: 0.8em;
  }

  table.calendar td {
    text-align: center;
    padding: 8px;
    border: 1px solid rgba(185, 185, 185, 0.13);
    background-color: white;
    min-width: 26px;
  }

  table.calendar tr:last-child td {
    border-bottom: none;
  }

  table.calendar td.month-name {
    font-weight: bold;
    text-align: left;
    cursor: default;
    border-left: none;
  }

  table.calendar td.prev-month,
  table.calendar td.next-month {
    color: transparent;
    cursor: default;
    pointer-events: none;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABZJREFUCB1jYEADmzdv/o8iRA0BoIEAKngPeSAlnXcAAAAASUVORK5CYII=");
  }

  table.calendar td.week-separator {
    pointer-events: none;
    padding: 0;
    width: 8px;
    min-width: 0;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABZJREFUCB1jYEADmzdv/o8iRA0BoIEAKngPeSAlnXcAAAAASUVORK5CYII=");
  }

  table.calendar td.bolder {
    font-weight: bold;
  }

  /* Single selected day */
  table.calendar td.selected {
    background-color: orangered;
    color: white;
    font-weight: bold;
  }

  /* Selected range */
  table.calendar td.range {
    background-color: rgba(255, 69, 0, 0.7);
    font-weight: bold;
    color: white;
  }

  table.calendar td.range-left {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    overflow: hidden;
    background: orangered;
  }

  table.calendar td.range-right {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: hidden;
    background: orangered;
  }

  div.calendar-controls {
    margin: 5px auto;
    display: table;
    font-size: 25px;
    line-height: 35px;
  }

  div.calendar-controls div {
    display: inline;
  }

  div.calendar-controls .current-year {
    margin: 0 30px;
    user-select: none;
  }

  div.calendar-controls .control {
    font-weight: bolder;
    color: #323232;
    font-size: 1.2em;
    cursor: pointer;
    user-select: none;
  }

  div.calendar-controls .today {
    position: absolute;
    right: 15px;
    line-height: 35px;
    font-size: 0.6em;
    text-transform: uppercase;
  }

  /* Custom classes */
  table.calendar td.holidays {
    color: white;
    font-weight: bold;
    cursor: help;
    background-color: $theme-mid;
  }
}
