.module-submenu-item.submenu-jobinvoices {
	.btn-edit-clients-invoices {
		position: absolute;
		right: 29px;
		bottom: 20px;
	}
}

.component-jobs-clients_list {
	.data-grid-footer {
		ion-row {
			ion-col:first-child {
				max-width: 25% !important;
			}
			ion-col:last-child {
				min-width: 75% !important;
			}
		}
	}
}

// Modals
.client-message-modal {
	.message-container {
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: 4px;
		font-size: 0.95em;

		.message-row {
			display: flex;
			flex-direction: row;

			.message-label {
				flex: 0 0 100px;
				font-weight: bold;
			}

			.message-value {
				flex: 1 1 100%;
			}

			&.mr-full-height {
				flex: 1 1 100%;
				height: 0;

				.message-value {
					overflow-y: auto;
				}
			}
		}
	}
}
