@import '../../assets/scss/theme-defaults.scss';

.component-scheduling {
	.scheduler-timeline {
		height: calc(100vh - 170px);

		&.resource-mode-workers {
			.mbsc-timeline-row {
				height: 82px;
			}
		}
	}

	.segments-resource-mode {
		justify-content: start;

		ion-segment-button {
			max-width: 110px;
			min-width: 110px;
		}
	}

	.segments-view-mode {
		ion-segment-button {
			max-width: 100px;
			min-width: 100px;
		}
	}

	.scheduling-calendar-nav {
		float: right;
		margin-top: -1px;
	}

	.scheduling-calendar-today {
		float: right;
	}

	.timeline-resource {
		.tr-text {
			line-height: 18px;
		}

		div p {
			font-weight: normal;
			color: var(--ion-color-medium);
			margin: 0;
			padding: 0;
		}

		.job-icon {
			margin-right: $margin-small;
		}
	}

	.mbsc-timeline-resource:has(.highlighted-resource) {
		div p {
			color: #555;
		}
	}

	/* Calendar overrides */
	.mbsc-timeline-events {
		/* Provide room for the cell background icons */
		top: 14px;
	}

	.mbsc-timeline-event {
		cursor: help;

		&.event-type-appointment {
			cursor: pointer;
		}

		&.connected-highlight {
			.mbsc-timeline-event-background {
				background: $theme-mid !important;
			}

			.mbsc-timeline-event-background::after {
				display: none;
			}
		}
	}

	.mbsc-timeline-resource-col {
		/* Expand the resources column */
		width: 20em;
	}

	.mbsc-timeline-parent {
		/* Minimal height for resource parent rows */
		$timeline-parent-height: 32px !important;
		min-height: $timeline-parent-height;
		height: $timeline-parent-height;
		overflow: hidden;
	}

	.view-mode-month {
		/* Month-view columns wider than default */
		.mbsc-timeline-column,
		.mbsc-timeline-header-column {
			width: 199px;
		}
	}

	.events-count-container {
		display: flex;
		justify-content: center;

		ion-badge {
			margin-top: 1px;
			font-weight: normal;
		}
	}

	/* Rendering helpers */
	.resources-footer-cell {
		display: flex;
		gap: 8px;
		text-align: right;
		line-height: 12px;

		svg {
			margin-top: -1px;
		}
	}
	.footer-cell {
		text-align: right;
		padding-right: $padding-xsmall;
	}

	.bg-job-target-dates {
		background-color: lighten($theme-beta-tertiary, 21%);
	}

	.bg-non-working-worker-day {
		z-index: 1;
		background-color: transparent;
	}
}

/* Side menu */
ion-menu[menuid='schedulingSideMenu'] {
	--width: 640px;

	&::part(container) {
		border-top-left-radius: var(--ion-border-radius-medium);
		border-bottom-left-radius: var(--ion-border-radius-medium);
	}

	ion-header {
		--ion-background-color: transparent;
		border-bottom: 1px solid #dcdcdc;

		ion-toolbar {
			box-shadow: none !important;
			color: var(--ion-text-color);
			--ion-background-color: white;
		}
	}

	ion-content {
		color: var(--ion-text-color);
		--ion-background-color: white;
		--padding-start: 30px;
		--padding-end: 26px;
	}

	.btnSideMenuClose {
		cursor: pointer;
		margin: -4px 8px -4px 0;
		padding: 4px 8px;
		position: absolute;
		top: 6px;
		left: 24px;
	}

	.prop-form ion-col,
	.form-time-row,
	.select-skill,
	.prop-mobi-select-inline {
		padding: 0;
		margin-bottom: $margin-small !important;
	}

	.prop-mask {
		width: calc(100% - 2px);
		height: calc(100% - 1px);
		top: 1px;
		left: 1px;
		border-radius: $border-radius-medium;
	}

	.curItem {
		color: $theme-std;
		font-weight: bold;

		&::before {
			content: '⯈';
			position: absolute;
			left: -19px;
			font-size: 1.2em;
			color: $theme-mid;
		}
	}

	.alert-clashes {
		margin: 0 0 $margin-small 0;
	}

	.workers-list-skills-select {
		width: 100%;
		border-color: #aaaaaa;
		border-radius: $border-radius-small;
	}
}

/* Modals */
.scheduling-day-schedule-modal {
	.cal-container {
		display: flex;
		flex-direction: column;
		height: 100%;
		max-height: inherit;
	}

	.mbsc-schedule-grid-scroll {
		height: auto !important;
		max-height: inherit !important;
	}

	.mbsc-schedule-header-item {
		/* Hide the day-of-week numbers */
		visibility: hidden;
	}

	.mbsc-schedule-event-hover {
		cursor: default;
	}

	.mbsc-schedule-event-inner {
		display: flex;
		flex-direction: column;
		gap: 4px;
		padding: $padding-small;

		.mbsc-schedule-event-title {
			font-size: 1.5em !important;
		}

		.mbsc-schedule-event-range {
			font-size: 1.3em !important;
		}
	}

	&.resource-mode-workers {
		.mbsc-calendar {
			margin-top: -130px;
		}
		.mbsc-calendar-header,
		.mbsc-schedule-header {
			visibility: hidden;
		}
	}

	&:not(.resource-mode-workers) {
		.mbsc-calendar {
			margin-top: -110px;
		}
	}

	.resource-title {
		display: flex;
		justify-content: center;
		gap: 10px;

		div {
			display: flex;
			flex-direction: row-reverse;
			justify-content: center;
			align-items: center;
		}

		.resource-avatar-outer {
			margin: 0;
		}
	}
}

/* Misc */
.skill-option {
	display: flex;
	gap: 0.6em;

	.has-skill {
		color: $theme-beta-quinary-dark;
	}

	.skill-option-man-hours-label {
		font-size: 0.8em;
		font-weight: lighter;
		white-space: nowrap;
	}
}

.worker-option {
	display: flex;
	gap: 3px;

	&.avail-1 div:not(:nth-child(3)) {
		color: darkorange;
	}

	&.avail-2 div:not(:nth-child(3)) {
		color: crimson;
	}

	div {
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 100%;

		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.mh-label {
		font-size: 0.8em;
		font-weight: lighter;
		text-align: right;
		flex-basis: 32%;
	}

	.btn-location {
		flex-basis: 14%;
		margin-left: 6px;
		text-align: center;

		svg {
			color: black;
		}

		&:hover {
			svg {
				color: $theme-std;
			}
		}
	}
}

.clash-option {
	display: flex;
	gap: 3px;

	div {
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 36%;

		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.clash-option__worker-name {
		flex-basis: 27%;
	}

	.clash-option__date-time {
		flex-basis: 37%;
		text-align: right;
		font-size: 0.9em;
	}
}

.scheduling-work-locations-select,
.react-select__menu-portal {
	.work-location-option {
		display: flex;
		flex-direction: row;
		align-items: center;

		:first-child {
			flex: 0 0 28px;
			text-align: center;
			margin-top: -1px;
		}
	}
}

.alert-scheduling-appointment-deletion {
	--width: 320px;
	--max-width: 320px;
}

.scheduling-location-popover-content {
	--padding-top: #{$padding-small};
	--padding-bottom: #{$padding-small};
	--padding-start: #{$padding-small};
	--padding-end: #{$padding-small};

	.location-option {
		display: flex;
		flex-direction: row;
		font-size: 0.9em;

		.location-option-icon {
			flex: 0 0 26px;
			overflow: hidden;
			text-align: center;
		}
	}
}
