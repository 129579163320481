@import "./../../../assets/scss/theme-defaults.scss"; // Use defaults defined there
@import "./../../../assets/scss/functions.scss"; // Use defaults defined there


.messageBlock {
	display: flex;
	height: 100%;
}

.messages {
	flex: 1;
	display: flex;
	flex-direction: column;
	height: 100%;

	.content {
		background-color: var(--ion-color-chat-grey);
		height: auto;
		flex: 1;
		display: flex;
		justify-content: center;
		padding: $padding-xsmall;
	
		ion-spinner {
			text-align: center;
			padding-top: 50px;
		}
	}

	ion-toolbar ion-item.chat {
		--background: var(--ion-color-tertiary);
		--border-color: var(--ion-color-tertiary);
	}

	.message-box {
		border-radius: #{$border-radius-medium};
		margin: 10px 0;
		background-color: var(--ion-color-white);
		border: 1px solid var(--ion-color-light);
		width: 100%;
		display: flex;
		align-items: center;

		ion-textarea {
			border-color: var(--ion-color-white);
			border-radius: #{$border-radius-medium};
			--padding-top: #{$padding-small};
			--padding-end: #{$padding-small};
			--padding-bottom: #{$padding-small};
			--padding-start: #{$padding-small};
		}

		.divider {
			width: 2px;
			height: 80%;
			margin: 0 #{$margin-small};
			background-color: var(--ion-color-light);
		}

		form {
			display: flex;
			align-items: center;
			height: 100%;
		}
	}

	.message-row {
		flex-direction: column;
		display: flex;
		width: 100%;
	}

	.reply {
		width: calc(100% - (#{$padding-large} + #{$padding-large}));

		.reply-close {
			position: absolute;
			right: calc(#{$padding-large} + 11px);
			top: 6px;
			z-index: 1000;
		}
	}

	@each $size,
	$size-value in $screen-breakpoints {
		.progress-section {
			@media (min-width: $size-value) {
				@if ($size-value <=576px) {
					grid-template-columns: 1fr;
				}
	
				@if ($size-value >=768px) {
					grid-template-columns: 1fr 1fr;
				}
	
				@if ($size-value >=1200px) {
					grid-template-columns: 1fr 1fr 1fr;
				}
			}
		}
	}

	.progress-section {
		width: 100%;
		display: grid;
		margin: 10px 0 0 0;

		.progress-item {
			width: 100%;
			flex: 1;
			padding: 0 #{$padding-small};

			ion-progress-bar {
				--background: var(--ion-background-color)
			}
		}
	}
}
.emoji-popover {
	--height: 450px;
	--width: 350px;
}

ion-row.centered {
	display: flex !important;
	align-items: center !important;
	height: 100%;

	ion-col {
		display: flex !important;
		align-items: center !important;
		height: 100%;
		
		&.text-right {
			justify-content: right;
		}
	}
}