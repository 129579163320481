@import '../../assets/scss/theme-defaults.scss';

.tab-ts-daily-hourly-timesheets,
.tab-ts-daily-hourly-exception-report,
.tab-ts-worker-exceptions-report,
.tab-ts-worker-allocations-report,
.timesheets-worker-exceptions-report-modal {
	.timesheets-header-container {
		display: flex;
		flex-direction: column;

		.timesheets-header-controls-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding: $padding-xsmall;
			border-radius: $border-radius-medium;
			background-color: $theme-light;

			ion-button {
				flex: 0 0 180px;
			}
		}

		.timesheet-header-controls {
			display: flex;
			flex-direction: row;
			justify-content: center;
			gap: 8px;
			width: 100%;
			min-width: 600px;

			.sel-my-team {
				flex: 0 0 116px;
				font-size: 14px;
			}

			.sel-payment-method {
				flex: 0 0 269px;
			}

			.sel-years {
				flex: 0 0 130px;
			}

			.sel-weeks {
				flex: 0 0 244px;
			}

			ion-button {
				flex: 0 0 46px;
				margin: 1px 0 0 0;

				svg {
					font-size: 1em;
				}
			}

			.week-ending-options-icon {
				color: var(--ion-color-success);
				margin-left: $margin-small;
				padding-top: 5px !important;

				&.pending {
					color: var(--ion-color-light-grey);
				}
			}

			.timesheet-sel-weeks-react-select__option--is-selected {
				.week-ending-options-icon {
					color: white;
				}
			}
		}
	}
}

.tab-ts-daily-hourly-timesheets {
	display: flex;
	flex-direction: column;
	height: 100%;

	.cal-container {
		position: relative;
		display: flex;
		flex-direction: column;
		height: calc(100vh - 372px);
		min-height: 300px;
		margin-bottom: 6px;
		box-shadow: #{$google-box-shadow};

		.mbsc-calendar {
			margin-top: 7px;

			.mbsc-calendar-header {
				display: none;
			}

			.mbsc-timeline-resource-col {
				/* Expand the resources column */
				width: 580px;
			}

			.mbsc-schedule-event-custom {
				padding-bottom: 6px;
				cursor: pointer !important;
			}

			.mbsc-schedule-event {
				/* Horizontally align the icons */
				display: flex;
				justify-content: center;

				.icon-exceptions,
				.icon-exceptions-approved,
				.icon-no-exceptions {
					font-size: 26px;
					padding: 6px;
					cursor: pointer;
				}

				.icon-exceptions {
					color: var(--ion-color-danger);
				}

				.icon-exceptions-approved {
					color: var(--ion-color-success);
				}

				.icon-no-exceptions {
					color: var(--ion-color-success);
				}

				/* Icon hover state dictated by event cell hover class state */
				&.mbsc-schedule-event-hover {
					.icon-exceptions {
						color: darkorange !important;
					}

					.icon-exceptions-approved {
						color: darkgreen;
					}

					.icon-no-exceptions {
						color: darkgreen;
					}
				}
			}

			.mbsc-schedule-event {
				cursor: default;
			}

			.bg-cal-cell-ok {
				background-color: #ecfbf8;
			}

			.bg-cal-cell-warn {
				background-color: #fbe9e9;
			}

			.bg-cal-cell-warn-ok {
				background-color: #ecfbf8;
			}
		}

		.msg-no-timesheets {
			$msg-width: 380px;
			$msg-height: 144px;

			position: absolute;
			top: calc(50% - calc($msg-height / 2));
			left: calc(50% - calc($msg-width / 2));
			width: $msg-width;
			height: $msg-height;

			ion-card {
				margin: 0;
				height: $msg-height;
				min-height: $msg-height;
				background-color: #f9f9f9;
				box-shadow: none;
				border: 1px solid #f2f2f2;
			}

			ion-card-title {
				font-weight: bold;
			}

			ion-card-content {
				font-size: 1em;
				color: black;
			}
		}
	}

	.resource-controls {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.resource-avatar-container {
			flex: 0 0 280px;
			margin: 2px 0 0 2px;

			.resource-avatar-extra-info {
				p {
					width: 220px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
		}

		.resource-info-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			flex: 0 0 120px;
			margin-top: -3px;
			font-weight: normal;
			font-size: 0.9em;
		}

		.resource-approval-controls {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			flex: 0 0 140px;
			font-weight: normal;
			margin-top: -3px;
			gap: 6px;
			font-size: 0.9em;

			div {
				display: flex;
				flex-direction: row;
				justify-content: end;
				align-items: center;
			}

			.ac-left {
				flex: 1;
			}

			.ac-right {
				flex: 0 0 50px;
			}
		}
	}

	.cal-controls {
		display: flex;
		justify-content: end;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.tab-ts-daily-hourly-exception-report {
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 200px;

	.row-lower-controls {
		display: flex;
		flex-direction: row;
		justify-content: end;
		padding: 0;
		margin: 3px 0 0 0;

		ion-button {
			margin: 0;
		}
	}

	.col-has-exceptions {
		ul {
			margin: $margin-small 0;

			li {
				list-style-type: disc;
				line-height: 1.6em;
			}
		}
	}
}

.tab-ts-worker-exceptions-report,
.tab-ts-worker-allocations-report {
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 300px;

	.lbl-filter-by-worker {
		margin-bottom: 7px;
	}

	.filter-by-worker {
		width: 260px;
	}
}

// Modals
.timesheets-allocation-modal {
	.header-row {
		&.hr-expected-items {
			background-color: $theme-alt;
		}

		&.hr-timesheet,
		&.hr-behaviour {
			background-color: #528190;
		}
	}

	.cell-time {
		display: flex;
		flex-direction: row;
		justify-content: start;
		align-items: baseline;

		&:not(:has(.duration-units)) {
			align-items: inherit;
		}

		.ts-time-input * {
			cursor: pointer;
		}
	}

	.cell-toggle {
		display: flex;
		flex-direction: column;
		justify-content: center;

		ion-toggle {
			width: 36px;
			height: 80%;
			--handle-width: 16px;

			&.toggle-checked::part(track) {
				background-color: var(--ion-color-success);
			}
		}
	}

	.duration-units {
		font-size: 0.8em;
		padding-left: 3px;
	}

	.cost-head-control-container {
		padding: 1px 0;
		cursor: pointer;
		color: $theme-beta-quinary-dark;

		&:hover {
			color: $theme-std !important;
		}

		&.choice-made {
			color: var(--ag-data-color);
		}

		svg {
			margin-right: 4px;
		}
	}

	.dr-behaviour {
		&[col-id='col_e'] {
			line-height: 25px;

			.icon-behaviour-good {
				color: var(--ion-color-light-grey);
			}

			.icon-behaviour-bad {
				color: var(--ion-color-danger);
			}
		}
	}
}

.timesheets-worker-exceptions-report-modal {
	.content-container {
		display: flex;
		flex-direction: column;
		height: 100%;

		.header-container {
			flex: 0;
		}

		.grid-container {
			flex: 1;
			min-height: 200px;
		}
	}

	.aggregate-score-container {
		margin: 5px 0 0 34px;
		font-size: 15px;

		ion-chip {
			margin: -1px 0 0 0;
			line-height: 18px;
			cursor: default;
		}
	}
}

.timesheets-cost-head-modal {
	ion-content {
		min-height: 300px;
	}
}
