@import './../../../assets/scss/theme-defaults.scss'; // Use defaults defined there.

.worker-container {
	background: var(--ion-color-white);
	display: flex;
	flex-direction: column;
	min-width: 260px;
	height: 100%;

	.worker-content {
		overflow-y: auto;
	}

	.worker-title {
		padding: #{$padding-std};
		border-bottom: 1px solid var(--ion-color-mid-light-grey);
	}

	.worker-correct {
		display: flex;
		flex-direction: row;
		justify-items: center;
		align-items: center;
		padding: #{$padding-xsmall} #{$padding-std};
	}

	.sub-title {
		--border-color: transparent;
		--ion-color-shade: transparent !important;
		--ion-color-base: var(--ion-color-light-grey-tint) !important;
	}

	.list-item-title {
		--border-color: transparent;
		--ion-color-shade: transparent !important;
		--padding-start: #{$padding-small};
		ion-title {
			padding-inline: #{$padding-small} !important;
		}
	}

	ion-list {
		padding: 0;
		ion-item:last-child {
			--border-color: transparent;
			--ion-color-shade: transparent !important;
		}
	}

	ion-toolbar {
		ion-title {
			padding: 0 #{$padding-std};
		}
	}
}

.gm-style .worker-content .conversation-item {
	border-bottom: 1px solid var(--ion-color-mid-light-grey);
}