@import './../../../../assets/scss/theme-defaults.scss';

.component-jobs-estimates_list,
.component-jobs-invoices_list {
	.row-lower-controls-left {
		margin-top: 3px;
	}

	.row-lower-controls-right > div {
		display: flex;
		justify-content: right;
	}
}

div[class^='component-jobs-estimates'] .items-body,
div[class^='component-jobs-invoices'] .items-body {
	.react-select-container:not(.react-select-autocomplete) {
		.react-select__value-container {
			margin-top: 1px;
		}
	}
}

// Item Catalogue overrides
.catalogue-items-modal {
	.component-utilities-item_catalogue {
		height: auto !important;
		margin-top: 20px;

		.data-grid-container {
			height: 450px !important;
		}
	}
}

.icon-add-catalogue-item {
	margin-right: 5px;
}
ion-modal.item-modal {
	// Force the backdrop and shadow details back on for this inner modal
	--backdrop-opacity: 0.32 !important;
	--box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
}
