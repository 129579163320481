.submenu-utilitiesstylesdefaultstyles {
	&.propeller-content-card {
		padding-top: 5px;
	}
}

.component-utilities-styles-terms_and_conditions {
	height: calc(100vh - 170px);
	overflow: hidden;

	.wysiwyg-editor-class {
		width: 100%;
		height: calc(100vh - 318px) !important;
	}
}
