@import './../../../../assets/scss/theme-defaults.scss';

$base-border-colour: #dee1e4;
$base-background-colour: #eff3f6;

// Management structure tree container
.css-11uc5e6 {
	width: 100%;
	height: 100%;
	overflow: scroll;
}

.alert-info {
	.MuiAlert-message {
		display: flex;
		flex-direction: row;
		width: 100%;
		gap: 10px;

		.info-left {
			flex: 1 1 100%;
		}

		.info-right {
			flex: 0 0 146px;

			.key-self-employed {
				float: left;
				border-radius: $border-radius-medium;
				padding: 8px 14px;
				background-color: $base-background-colour;
				border: 2px solid var(--ion-color-self-employed);
			}
		}
	}
}

.org-tree-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	height: calc(100vh - 266px);
	border: 1px solid $lightGrey;
	border-radius: $border-radius-medium;
	padding: 2px;
	margin: 0 1px 0 10px;
}

.tree-card {
	user-select: none;
}

.tree-card.tree-node {
	border: 2px solid $base-border-colour;
}

.tree-card.tree-node-archived {
	opacity: 0.5;
	border: 2px solid $theme-beta-quinary-dark;
}

.tree-card.self-employed {
	border: 2px solid var(--ion-color-self-employed);
}

.tree-node-hover {
	color: white !important;
	background-color: $theme-alt !important;
	border: 2px solid $theme-mid !important;
}

.tree-card.react-draggable {
	cursor: move;
}

.tree-node,
.dragger-node {
	background-color: $base-background-colour;
	box-shadow: none;
}

.dragger-node {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	width: 100%;
	box-shadow: none;
	border: 2px solid $base-border-colour;

	.dragger-left {
		flex: 0 0 115px;
		text-align: left;
	}

	.dragger-right {
		flex: 1 1 100%;
		text-align: left;
		overflow: hidden;

		* {
			margin: 0;
			line-height: 1.5em; /* Stops letters like 'g' from being cut off */
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.dragger-edge {
		flex: 0 0 14px;
		display: flex;
		align-items: center;
		justify-content: end;
		overflow: hidden;
	}
}

.list-container {
	padding: 0 4px;
	height: calc(100vh - 288px);
	overflow-y: auto;
}

/* Messages */
.msg-to-be-assigned {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: -$margin-std $margin-small $margin-small $margin-small;

	.line {
		display: flex;
		flex-grow: 1;

		.liner {
			flex-grow: 1;
			height: 50%;
			border-bottom: 1px solid $lightGrey;
		}
	}

	ion-text {
		text-align: center;
		margin: 0 $margin-small;

		* {
			padding-top: 4px;
		}
	}
}

.msg-all-assigned {
	display: flex;
	flex-direction: row;
	justify-content: left;

	svg {
		font-size: 20px;
		margin-right: 6px;
	}
}

.msg-drop-target {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	pointer-events: none; /* Don't capture the drop */

	* {
		pointer-events: none; /* Don't capture the drop */
	}

	svg {
		font-size: 16em;
		color: #f5f5f5;
	}
}
