@import "./../../../assets/scss/variables.scss";

.search-menu {
	flex: 0 0 30%;
	background-color: var(--ion-background-color);
	border-left: 10px solid var(--ion-background-color);

	.content {
		height: 100% !important;
		display: flex;
		justify-content: center;
		padding: $padding-xsmall;

		ion-spinner {
			padding-top: 50px;
		}

		> p {
			padding-top: 20px;
		}
	}
	
	ion-content::part(scroll) {
		--padding-bottom: 0;
		--padding-top: 0;
		--padding-start: 0;
		--padding-end: 0;
	}
}