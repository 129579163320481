@import './../../assets/scss/theme-defaults.scss'; // Use defaults defined there

.data-grid-container {
	display: flex;
	flex-direction: column;
	margin: 0 5px;
	height: 100%;

	.data-grid {
		flex: 1;
		width: 100%;
		margin-bottom: 5px;
		position: relative;

		.ag-root-wrapper {
			border-radius: $border-radius-medium;
		}

		.ag-body-viewport {
			@include scrollbars();
		}
	}
	.data-grid-title {
		margin-bottom: 0;
		line-height: calc(1rem + 12px);
	}
	.data-grid-header {
		margin-top: 5px;
		margin-bottom: 5px;
	}
	.data-grid-footer {
		.data-table-filter-buttons {
			align-items: center;
			display: flex;
		}
	}
	.data-table-filter-buttons {
		padding: 0;
	}
	.data-table-buttons .table-buttons {
		margin: 0;
	}
	ion-input#filter-text-box {
		max-height: 34px;
		margin-top: -2px;
	}
}

/* Global table button styles - when using FilterButtons component by itself, e.g. HolidayChart */
.data-table-buttons {
	flex: 0;

	.row-slot {
		display: flex;
		justify-content: right;
		align-items: flex-end;

		ion-button,
		ion-row {
			margin-right: 0;
			margin-bottom: 0;
		}
	}

	#select-year {
		width: 90px;

		&.offset-mode {
			width: 130px;
		}
	}

	ion-col {
		padding-top: 0;
		padding-bottom: 0;
	}
}

/* Cell style types */
.ag-cell {
	&.duration-type,
	&.date-type,
	&.currency-type {
		text-align: right;
	}
}

/**
 * Grid Theme Alpha
 * A specific Propeller Studios style for custom row and colour rendering. Examples of
 * usage include the Timesheets Allocation Modal and Worker Timesheets Cumulative Costs.
 * The aim is to hide the AG Grid header row and use abstract columns and multiple,
 * arbitrary headers and sub-headers.
 */
.grid-theme-alpha {
	.ag-root {
		--ag-background-color: var(--ion-color-mid-light-grey);
		--ag-odd-row-background-color: var(--ion-color-mid-light-grey);

		.ag-header {
			display: none;
		}
	}

	.header-row {
		color: white;
		font-weight: bold;

		svg {
			margin: 0 4px 1px 0;
		}
	}

	.sub-header-row {
		color: white;

		&.shr-alpha {
			background-color: $theme-beta-primary;
		}

		&.shr-beta {
			background-color: #80b4bb;
		}
	}

	.ag-row:has(.sub-total-row),
	.ag-row:has(.is-before-sub-total-row),
	.ag-row:has(.is-last-data-row) {
		border-bottom-color: #ccc;
	}

	.total-row,
	.sub-total-row {
		background-color: #e5e5e5;
		font-weight: bold;
	}

	.spacer-row {
		background-color: white;
	}
}

/* Misc */
.ag-grid-row-button {
	margin: 0;
	margin-top: -12px;
	height: 30px;
}
