@import "./../../../assets/scss/theme-defaults.scss"; // Use defaults defined there

.message {
	position: relative;
	box-sizing: border-box;
	padding: 0.5rem 1rem;
	margin: 0.1rem 0.1rem 0.1rem 30px;
	width: fit-content;
	max-width: 66%;
	background-color: transparent;
	--background: var(--ion-color-light-white);

	ion-card,
	ion-card-header {
		padding: 0;
		color: var(--ion-color-dark);
		margin: 0;
		box-shadow: none;
	}
	
	ion-card-header ion-item,
	ion-card-content {
		background: var(--ion-color-light-white);
		--background: var(--ion-color-light-white);
		--border-style: none;
		color: var(--ion-color-dark);
	}

	ion-card {
		position: relative;
		--border-style: none;
		--background: var(--ion-color-white);
		background: var(--ion-color-white);
		border-radius: #{$border-radius-medium};
		border: 1px solid var(--ion-color-white);
		white-space: pre-line;
		grid-area: message;
		font-weight: normal;
		word-break: normal;
		--ion-color-step-600: var(--ion-color-dark);
		color: var(--ion-color-dark);
		--color: var(--ion-color-dark);
	}

	/*&:not(.preview):before {
		content: ' ';
		position: absolute;
		width: 0;
		height: 0;
		right: auto;
		left: -1px;
		top: 8px;
		bottom: auto;
		border: 21px solid;
		border-color: var(--ion-color-light-grey) transparent transparent transparent;
	}*/

	&.not-read {
		& ion-card {
			border: 1px solid rgba(82, 131, 255, 1);
			box-shadow: 0px 0px 18px 4px rgba(82, 131, 255, 1);
		}
	}

	&.own {
		margin: 0.1rem 30px 0.1rem auto;
		--background: var(--ion-color-chat-blue);
		
		& ion-card {
			--background: var(--ion-color-chat-blue);
			background: var(--ion-color-chat-blue);
			border: 1px solid var(--ion-color-chat-blue);
			--ion-color-step-600: var(--ion-color-dark);
			color: var(--ion-color-dark);
		}

		& ion-card-header ion-item,
		& ion-card-content {
			background: var(--ion-color-chat-blue);
			--background: var(--ion-color-chat-blue);
			color: var(--ion-color-dark);
		}
		
		/*&:not(.preview):before {
			content: ' ';
			position: absolute;
			width: 0;
			height: 0;
			left: auto;
			right: -1px;
			top: 8px;
			bottom: auto;
			border: 21px solid;
			border-color: var(--ion-color-chat-blue) transparent transparent transparent;
		}*/

		.emoji-container {
			background-color: var(--ion-color-chat-blue) !important;
			border: 1px solid var(--ion-color-chat-blue) !important;
		}
	}

	.time-read {
		margin-top: 5px;
		text-align: left;
		vertical-align: middle;
		color: var(--ion-color-dark);

		span {
			margin-right: 10px;
			font-size: 0.9rem;
			line-height: 0.9rem;
		}

		.primary-icon {
			color: var(--ion-color-primary);
		}

		.success-icon {
			color: var(--ion-color-success);
		}
	}

	&.own .time-read {
		text-align: right;
	}

	.doc-link {
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: var(--ion-background-color);
		color: var(--ion-color-dark);
		padding: 5px 10px;

		div {
			width: 100%;
			display: flex;
			flex-direction: column;
		}

		div.file-icon {
			width: 40px;
			font-size: 1.2rem;
		}

		.file-size {
			font-size: 0.8rem;
			color: var(--ion-color-medium);
		}
	}
	
	video, img {
		max-width: 100%;
	}

	.thumbnail {
		position: relative;
		width: 100%;
		max-width: 250px;
		min-width: 250px;
		height: 200px;
		overflow: hidden;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;

		.play-icon {
			color: var(--ion-color-white);
			font-size: 2rem;
			border: 2px solid var(--ion-color-white);
			border-radius: 50%;
			width: 50px;
			height: 50px;
			text-align: center;
		}
	}
	
	&.preview {
		margin: 0;
		padding: 0;
		padding-top: $padding-small;

		&.message {
			max-width: 100%;
			width: 100%;
			padding-left: $padding-small;
			padding-right: $padding-small;

			&:not(.own) ion-card {
				border: 1px solid var(--ion-color-white);
				background: var(--ion-color-white);
			}

			&:not(.own) ion-card-header ion-item,
			&:not(.own) ion-card-content {
				--background: var(--ion-color-white);
				background: var(--ion-color-white);
				--color: var(--ion-color-dark);
				color: var(--ion-color-dark);
				--ion-color-step-600: var(--ion-color-dark);
				--ion-color-step-600: var(--ion-color-dark);
			}
		}
	}

	.reactions {
		margin-top: -15px;

		.emoji-container {
			position: relative;
			display: inline-flex;
			width: 35px;
			height: 35px;
			border-radius: 50%;
			background-color: var(--ion-color-white);
			border: 1px solid var(--ion-color-white);
			align-items: center;
			
			div {
				flex: 1;
				text-align: center;
			}

			.emoji-number {
				font-size: 0.7rem;
				line-height: 0.7rem;
				color: var(--ion-color-dark);
			}
		}
	}

	&.own .reactions .emoji-number {
		color: var(--ion-color-white);
	}

	mark {
		padding: 0;
	}
	
}

.no-bottom-margin {
	margin-bottom: 0;
}

.styles-module_wrapper__1I_qj {
	z-index: 100;
	background-color: var(--ion-background-color);
}

.heading {
	position: relative;
	width: 100%;
	text-align: center;
	color: var(--ion-color-medium);
	padding-bottom: 20px;

	&:before {
		content: " ";
		position: absolute;
		height: 10px;
		background-color: var(--ion-color-light-grey);
		width: 100%;
		left: 0;
		bottom: 0;
	}
}