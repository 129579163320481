@use "@material/elevation/mdc-elevation";
@import "./theme-defaults.scss";

// Returns the RGB format of a hex colour
@function toRGB($color) {
  @return "rgb(" + red($color) + ", " + green($color) + ", " + blue($color) +
    ")";
}

// Returns the RGBa format of a hex colour
@function toRGBa($color, $opacity) {
  @return "rgba(" + red($color) + ", " + green($color) + ", " + blue($color) +
    ", " + $opacity + ")";
}

// Returns white if the colour is below a defined brightness,
// else returns $font-color (assumed to be very dark)
@function text-contrast($color-value) {
  $brightness: calc(
    (
        red($color-value) * 299 + green($color-value) * 587 + blue($color-value) *
          114
      ) / 1000
  );

  @if (abs($brightness) < 129) {
    @return $theme-font-contrast;
  } @else {
    @return $theme-font-color;
  }
}

// Input a number from 0-100 and it returns a shade of the $theme-alt
// Used for settings background colours of elements
@function step-color($val) {
  @return change-color($theme-alt, $lightness: $val * 1%);
}

// Create a range of shades of the theme colour to be used.
// Can be used as a class e.g. ".color-40" to set background colour
// or as a variable e.g. "background-color: var(--color-40)"
// Automatically sets contained text colour for best contrast
$color-range: (
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  85,
  90,
  95
);

@each $color in $color-range {
  $current-color: change-color($theme-alt, $lightness: $color * 1%);

  :root {
    --color-#{$color}: #{$current-color};
  }

  .color-#{$color} {
    background-color: $current-color;
    * {
      color: text-contrast($current-color) !important;
    }
  }
}

.color {
  background-color: $theme-alt;
  * {
    color: text-contrast($theme-alt) !important;
  }
}

// Rename the Material elevation class names to shorten them

$elevation-list: (
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24
);

@each $elevation in $elevation-list {
  .z-#{$elevation} {
    @extend .mdc-elevation--z#{$elevation};
  }
}

// Create classes for hiding & showing elements (display: block) on breakpoints

$screen-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

@each $size, $size-value in $screen-breakpoints {
  .hide-#{$size} {
    @media (min-width: $size-value) {
      display: none;
    }
  }
}

@each $size, $size-value in $screen-breakpoints {
  .show-#{$size} {
    display: none;
    @media (min-width: $size-value) {
      display: block;
    }
  }
}
