@import './../../../assets/scss/theme-defaults.scss'; // Use defaults defined there.

.route-container {
	background: var(--ion-color-white);
	display: flex;
	flex-direction: column;
	min-width: 150px;

	.route-content {
		overflow-y: auto;
	}

	.route-title {
		padding: #{$padding-std};
		border-bottom: 1px solid var(--ion-color-mid-light-grey);
	}

	.route-correct {
		display: flex;
		flex-direction: row;
		justify-items: center;
		align-items: center;
		padding: #{$padding-xsmall} #{$padding-std};
	}
}

.gm-style .route-content .conversation-item {
	border-bottom: 1px solid var(--ion-color-mid-light-grey);
}