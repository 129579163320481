@import "./../../../../assets/scss/theme-defaults.scss";

.security-crud-rows-container {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.security-tab:nth-child(odd) {
  background-color: #f2f2f2;
}

.security-tab {
  width: 100%;
  min-height: 38px;
  overflow: hidden;
  font-size: 0.9rem;
  padding-left: 10px;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  background-color: #fff;

  display: flex;
  flex-direction: row;
  align-items: center;

  .security-tab--title {
    flex-basis: 75%;
  }

  .security-tab--controls {
    flex-basis: 25%;
    display: flex;
    flex-direction: row;
    justify-content: right;

    .security-tab--controls--container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 25%;

      label,
      input {
        cursor: pointer;
      }

      label {
        color: #333;
        font-size: 0.8rem;
        text-transform: capitalize;
        -webkit-user-select: none;
        user-select: none;
      }

      .module-icon {
        font-size: 1.2rem;
      }
    }
  }
}

.security-tab.is-module,
.security-tab.is-module * {
  color: #fff;
  background-color: $theme-alt;
}

.footer-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer-controls.justify-right {
  justify-content: right;
}

.app-item:nth-child(odd) {
	--background: #f2f2f2;
}

.app-item {
	font-size: 0.9rem;
	border-top: 1px solid #dee2e6;
	border-bottom: 1px solid #dee2e6;
	--padding-start: 30px;
	--inner-border-width: 0;
	--background: #fff;
	--min-height: 38px;
	
	ion-checkbox {
		margin: 0;
	}

	ion-label {
		margin: 0;
	}
	margin-bottom: 6px;
}