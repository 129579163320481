/* Print styles for external pages to allow normal flowing printing across multiple pages */
@media print {
	body:has(.external-page) {
		position: initial !important;
		max-height: initial !important;
		overflow: auto !important;

		ion-router-outlet {
			overflow: auto !important;
			contain: none !important;
			position: initial !important;
		}

		.ion-page {
			display: initial !important;
			position: initial !important;
		}

		ion-content {
			display: unset !important;
			position: unset !important;
			--offset-bottom: unset !important;
			--padding-top: 0;
			--padding-bottom: 0;
			--padding-start: 0;
			--padding-end: 0;
		}
	}
}
