@import './source-sans-pro.scss';

$theme-light: #f4f6f8;
$theme-light-grey: #e0e0e0;
$theme-mid: #20bbf1;
$theme-std: #2967d9;
$theme-dark: #252c3d;
$theme-alt: #496191;
$theme-accent: #dae8f2;
$theme-accent-dark: #1d4da5;

$theme-beta-primary: #7796bc;
$theme-beta-primary-dark: #3e68b2;
$theme-beta-secondary: #84d3ec;
$theme-beta-secondary-text: #69caeb;
$theme-beta-secondary-dark: #2fbced;
$theme-beta-tertiary: #85cfc5;
$theme-beta-tertiary-dark: #64c6b6;
$theme-beta-tertiary-darkest: #3f9284;
$theme-beta-quaternary: #8880bd;
$theme-beta-quaternary-dark: #756bc0;
$theme-beta-quinary: #d36a92;
$theme-beta-quinary-dark: #d25282;
$theme-beta-senary: #e9edf0;
$theme-beta-light: #ebf0f4;

$theme-background-light: #eaeef1;
$theme-background-mid: $theme-mid;
$theme-background-std: $theme-std;
$theme-background-dark: $theme-dark;
$theme-background-alt: $theme-alt;

$theme-light-highlight: #dfe0e1;

$theme-gradient: linear-gradient(
	300deg,
	rgba(89, 136, 231, 1) 0%,
	rgba(73, 97, 145, 1) 26%,
	rgba(55, 77, 120, 1) 100%
);

$theme-font-color: #000;
$theme-font-contrast: #fff;
$theme-font: 'Source Sans Pro', sans-serif;

$transition: all 0.2s linear;
$border-radius-large: 8px;
$border-radius-medium: 6px;
$border-radius-small: 3px;
$box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
	0 1px 5px 0 rgba(0, 0, 0, 0.12);
$google-box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
$google-large-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
$padding-std: 16px;
$padding-small: 10px;
$padding-xsmall: 6px;
$padding-large: 20px;
$margin-large: 16px;
$margin-std: 10px;
$margin-small: 6px;
$margin-xsmall: 2px;

$titleHeight: 69px;
$toolbarHeight: 60px;

$success: #5ba333;
$warning: #ffb738;
$danger: #c64a4a;
$medium: #9ea0aa;
$lightGrey: #b9b9b9;
$grey: #797979;
$chatGrey: #f2f2f2;
$chatBlue: #cde1f3;
$midLightGrey: #eeeeee;

$theme: (
	'primary': $theme-std,
	'secondary': $theme-mid,
	'tertiary': $theme-accent,
	'quaternary': $theme-beta-quaternary-dark,
	'success': $success,
	'warning': $warning,
	'danger': $danger,
	'medium': $medium,
	'light': $theme-light,
	'light-grey': $lightGrey,
	'mid-light-grey': $midLightGrey,
	'grey': $grey,
	'dark': $theme-dark,
	'white': #fff,
	'alt': $theme-alt,
	'accent': $theme-accent,
	'accent-dark': $theme-accent-dark,
	'chat-grey': $chatGrey,
	'chat-blue': $chatBlue,
	'theme-beta-primary': $theme-beta-primary,
	'theme-beta-tertiary': $theme-beta-tertiary,
	'theme-beta-tertiary-darkest': $theme-beta-tertiary-darkest,
	'theme-beta-quaternary-dark': $theme-beta-quaternary-dark,
	'theme-beta-quinary-dark': $theme-beta-quinary-dark,
	'self-employed': $theme-beta-tertiary-dark,
);

$icon-sizes: (
	'small': 0.8rem,
	'std': 1rem,
	'large': 1.8rem,
	'grid': 1.4rem,
	'table': 1.4rem,
);

h1 {
	font-size: 1.5rem;
	line-height: 1.5rem;
}

h2 {
	font-size: 1.3rem;
	line-height: 1.3rem;
}

h3 {
	font-size: 1.2rem;
	line-height: 1.2rem;
}

h4 {
	font-size: 1.1rem;
	line-height: 1.1rem;
}

h5 {
	font-size: 0.9rem;
	line-height: 0.9rem;
}

a {
	color: inherit;
	text-decoration: none;

	&:hover {
		color: inherit;
	}
}

li {
	list-style-type: none;
}

.font-bold {
	font-weight: bold;
}

.font-italic {
	font-style: italic;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

.flex {
	display: flex;
}

.block {
	display: block;
}

// Box Shadows - based on MUI
$z-1: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
	0px 1px 3px 0px rgb(0 0 0 / 12%);
$z-2: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
	0px 1px 5px 0px rgb(0 0 0 / 12%);
$z-4: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
	0px 1px 10px 0px rgb(0 0 0 / 12%);

// Form box shadows
$field-focus: 0 0 3px $theme-alt;
$field-valid: 0 0 3px var(--ion-color-success);
$field-invalid: 0 0 3px var(--ion-color-danger);

@mixin scrollbars {
	// Scrollbar handle & track - on ion-content only so that it doesn't overlap Toolbar
	&::-webkit-scrollbar {
		width: 12px;
		height: 12px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: $border-radius-medium;
		box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
		background-color: var(--ion-color-medium);
	}
	&::-webkit-scrollbar-track {
		border-radius: $border-radius-medium;
		box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
		background-color: var(--ion-color-light);
	}
}

@mixin scrollbarsHorizontal {
	// Scrollbar handle & track - on ion-content only so that it doesn't overlap Toolbar
	&::-webkit-scrollbar {
		height: 12px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: $border-radius-medium;
		box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
		background-color: var(--ion-color-medium);
	}
	&::-webkit-scrollbar-track {
		border-radius: $border-radius-medium;
		box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
		background-color: var(--ion-color-light);
	}
}

@mixin background-opacity($color, $opacity: 0.3) {
	background-color: $color; /* The Fallback */
	background-color: rgba($color, $opacity);
}

// Header and footer
$header-width: 675px;
$header-height: 100px;
$footer-width: 675px;
$footer-height: 100px;

// Client logo
$client-logo-width: 200px;
$client-logo-height: 150px;

:export {
	primary: $theme-std;
	secondary: $theme-mid;
	tertiary: $theme-accent;
	success: #5ba333;
	warning: #ffb738;
	danger: #c64a4a;
	medium: #9ea0aa;
	light: $theme-light;
	lightGrey: #b9b9b9;
	midLightGrey: $midLightGrey;
	grey: #797979;
	dark: $theme-dark;
	white: #fff;
	alt: $theme-alt;
	accent: $theme-accent;
	accentDark: $theme-accent-dark;
	chatGrey: #f2f2f2;
	chatBlue: #cde1f3;
}
